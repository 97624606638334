import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './EventTable.css';

const EventTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { eventName, userEmail, isEditing,currency = 'USD' } = location.state || {};

  const [offlinePlatforms, setOfflinePlatforms] = useState([]);
  const [onlinePlatforms, setOnlinePlatforms] = useState([]);
  const [offlineInvestments, setOfflineInvestments] = useState({});
  const [onlineInvestments, setOnlineInvestments] = useState({});
  const [offlineContraDeals, setOfflineContraDeals] = useState({});
  const [onlineContraDeals, setOnlineContraDeals] = useState({});
  const [totalInvestmentOffline, setTotalInvestmentOffline] = useState(0);
  const [totalInvestmentOnline, setTotalInvestmentOnline] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  // Helper to convert db_format to Title_Case
  const toTitleCase = (platform) => {
    return platform
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join('_');
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        // Fetch event details first to get platform lists
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/post_event_details/${eventName}`);
        const eventData = response.data[0];
        
        if (!eventData) return;

        // Get platform lists in Title_Case format
        const formattedOfflinePlatforms = eventData.offline_platforms.map(toTitleCase);
        const formattedOnlinePlatforms = eventData.online_platforms.map(toTitleCase);

        setOfflinePlatforms(formattedOfflinePlatforms);
        setOnlinePlatforms(formattedOnlinePlatforms);

        // Fetch investments (which include contra deals)
        const [offlineResponse, onlineResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/post_event_investment_offline/${eventName}`),
          axios.get(`${process.env.REACT_APP_API_URL}/post_event_investment_online/${eventName}`)
        ]);

        // Process offline investments and contra deals
        if (offlineResponse.data[0]) {
          const offlineData = offlineResponse.data[0];
          const formattedOffline = {};
          
          formattedOfflinePlatforms.forEach(platform => {
            const dbKey = platform.toLowerCase();
            formattedOffline[platform] = parseFloat(offlineData[dbKey]) || 0;
          });

          setOfflineInvestments(formattedOffline);
          setTotalInvestmentOffline(parseFloat(offlineData.totalinvestmentoffline) || 0);
          
          // Set offline contra deals from the response
          if (offlineData.contra_deals && typeof offlineData.contra_deals === 'object') {
            setOfflineContraDeals(offlineData.contra_deals);
          }
        }

        // Process online investments and contra deals
        if (onlineResponse.data[0]) {
          const onlineData = onlineResponse.data[0];
          const formattedOnline = {};
          
          formattedOnlinePlatforms.forEach(platform => {
            const dbKey = platform.toLowerCase();
            formattedOnline[platform] = parseFloat(onlineData[dbKey]) || 0;
          });

          setOnlineInvestments(formattedOnline);
          setTotalInvestmentOnline(parseFloat(onlineData.totalinvestmentonline) || 0);
          
          // Set online contra deals from the response
          if (onlineData.contra_deals && typeof onlineData.contra_deals === 'object') {
            setOnlineContraDeals(onlineData.contra_deals);
          }
        }

        setInitialLoad(false);
      } catch (error) {
        console.error('Error fetching event details:', error);
        setInitialLoad(false);
      }
    };

    if (eventName) {
      fetchEventDetails();
    }
  }, [eventName]);

  const handleInvestmentChange = (platform, value, isOnline) => {
    const numValue = parseFloat(value) || 0;
    
    if (isOnline) {
      setOnlineInvestments(prev => ({
        ...prev,
        [platform]: numValue
      }));
      
      const updatedInvestments = { ...onlineInvestments, [platform]: numValue };
      const newTotal = Object.values(updatedInvestments).reduce((sum, val) => sum + val, 0);
      setTotalInvestmentOnline(newTotal);
    } else {
      setOfflineInvestments(prev => ({
        ...prev,
        [platform]: numValue
      }));
      
      const updatedInvestments = { ...offlineInvestments, [platform]: numValue };
      const newTotal = Object.values(updatedInvestments).reduce((sum, val) => sum + val, 0);
      setTotalInvestmentOffline(newTotal);
    }
  };

  const normalizePlatformName = (platform) => {
    if (!platform) return '';
    // Keep existing capitalization but normalize any differences
    const normalizedName = platform
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('_');
    return normalizedName;
  };

const normalizeForComparison = (platform) => {
  // Make everything uppercase to avoid case-sensitive matching
  return platform.toUpperCase();
};

const handleContraDealChange = (platform, isOnline, checked) => {
  if (isOnline) {
    setOnlineContraDeals(prev => {
      // Find matching platform ignoring case
      const existingKey = Object.keys(prev).find(
        key => normalizeForComparison(key) === normalizeForComparison(platform)
      );
      return {
        ...prev,
        [existingKey || platform]: checked
      };
    });
  } else {
    setOfflineContraDeals(prev => {
      const existingKey = Object.keys(prev).find(
        key => normalizeForComparison(key) === normalizeForComparison(platform)
      );
      return {
        ...prev,
        [existingKey || platform]: checked
      };
    });
  }
};

  const handleSave = async () => {
    try {
      if (initialLoad) {
        console.log('Still loading initial data, cannot save yet');
        return;
      }

      const offlineData = {
        eventName,
        offlineInvestments: offlineInvestments,
        offlineContraDeals: offlineContraDeals,
        totalInvestmentOffline
      };

      const onlineData = {
        eventName,
        onlineInvestments: onlineInvestments,
        onlineContraDeals: onlineContraDeals,
        totalInvestmentOnline
      };

      console.log('Saving data:', { offlineData, onlineData });

      await Promise.all([
        axios.post(`${process.env.REACT_APP_API_URL}/save_post_event_offline/${eventName}`, offlineData),
        axios.post(`${process.env.REACT_APP_API_URL}/save_post_event_online/${eventName}`, onlineData)
      ]);

      navigate('/event-details', { 
        state: { eventName, userEmail, isEditing,currency } 
      });
    } catch (error) {
      console.error('Error saving event:', error);
      alert('Failed to save event: ' + (error.response?.data?.error || error.message));
    }
  };

  const handleBack = () => {
    navigate('/create-postevent', {
      state: { eventName, userEmail, isEditing,currency }
    });
  };

  return (
    <div className="event-table-container">
      <header className="event-table-header">
        <div className="event-table-breadcrumb">
          <span>Event Lists</span>
          <span className="separator">›</span>
          <span>{eventName}</span>
        </div>
      </header>

      <div className="event-table-content">
        <h2>Budget Allocation</h2>
        
        <div className="event-table-grid">
          {offlinePlatforms.length > 0 && (
            <div className="platform-section">
              <h3>Offline Platforms</h3>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Platform</th>
                      <th>Investment</th>
                      <th>Contra Deal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {offlinePlatforms.map(platform => (
                      <tr key={platform}>
                        <td>{platform.replace(/_/g, ' ')}</td>
                        <td>
                          <input
                            type="number"
                            value={offlineInvestments[platform] || ''}
                            onChange={(e) => handleInvestmentChange(platform, e.target.value, false)}
                            min="0"
                            placeholder="Enter amount"
                          />
                        </td>
                        <td className="contra-deal-cell">
                          <input
                            type="checkbox"
                            checked={offlineContraDeals[platform] || false}
                            onChange={(e) => handleContraDealChange(platform, false, e.target.checked)}
                            className="contra-deal-checkbox"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          
          {onlinePlatforms.length > 0 && (
            <div className="platform-section">
              <h3>Online Platforms</h3>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Platform</th>
                      <th>Investment</th>
                      <th>Contra Deal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {onlinePlatforms.map(platform => (
                      <tr key={platform}>
                        <td>{platform.replace(/_/g, ' ')}</td>
                        <td>
                          <input
                            type="number"
                            value={onlineInvestments[platform] || ''}
                            onChange={(e) => handleInvestmentChange(platform, e.target.value, true)}
                            min="0"
                            placeholder="Enter amount"
                          />
                        </td>
                        <td className="contra-deal-cell">
                          <input
                            type="checkbox"
                            checked={onlineContraDeals[platform] || false}
                            onChange={(e) => handleContraDealChange(platform, true, e.target.checked)}
                            className="contra-deal-checkbox"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        
        <div className="event-table-summary">
          <strong>Total Investment Offline: {currency} {totalInvestmentOffline}</strong>
          <strong>Total Investment Online: {currency} {totalInvestmentOnline}</strong>
        </div>
        
        <div className="event-table-actions">
          <button className="btn-back" onClick={handleBack}>Back</button>
          <button 
            className="btn-next" 
            onClick={handleSave} 
            disabled={initialLoad}
          >
            {initialLoad ? 'Loading...' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventTable;