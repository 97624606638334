import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import './EventDetails.css';
import NotesSection from './NotesSection';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const EventDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userEmail = location.state?.userEmail;
  const { eventName,currency = 'USD' } = location.state || {};
  const [onlineInvestments, setOnlineInvestments] = useState({});
  const [offlineInvestments, setOfflineInvestments] = useState({});
  const [totalOnlineBudget, setTotalOnlineBudget] = useState(0);
  const [totalOfflineBudget, setTotalOfflineBudget] = useState(0);
  const [metrics, setMetrics] = useState({
    overallRevenue: '',
    averageOrderValue: '',
    totalRegistrations: ''
  });
  const [metricsLoaded, setMetricsLoaded] = useState(false);

  useEffect(() => {
    if (eventName && userEmail) {
      fetchInvestmentonline(eventName);
      fetchInvestmentoffline(eventName);
      fetchMetrics(eventName, userEmail);
    }
  }, [eventName, userEmail]);

  const fetchMetrics = async (eventName, userEmail) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_metric/${eventName}`);
      console.log('Metrics response:', response.data); // Debug log
  
      if (response.data && response.data[0]) {
        // Use the correct property names from backend
        setMetrics({
          overallRevenue: response.data[0].overallRevenue?.toString() || '',
          averageOrderValue: response.data[0].averageOrderValue?.toString() || '',
          totalRegistrations: response.data[0].totalRegistrations?.toString() || ''
        });
        console.log('Set metrics to:', {
          overallRevenue: response.data[0].overallRevenue,
          averageOrderValue: response.data[0].averageOrderValue,
          totalRegistrations: response.data[0].totalRegistrations
        });
      }
      setMetricsLoaded(true);
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setMetricsLoaded(true);
    }
  };

  const fetchInvestmentonline = async (eventName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/post_event_investment_online/${eventName.trim()}`);
      const onlineData = response.data[0];
      if (onlineData) {
        const filteredOnlineData = Object.entries({
          tiktok: onlineData.tiktok || 0,
          facebook: onlineData.facebook || 0,
          instagram: onlineData.instagram || 0,
          linkedin: onlineData.linkedin || 0,
          google_demand_gen: onlineData.google_demand_gen || 0,
          google_pmax: onlineData.google_pmax || 0,
          google_search: onlineData.google_search || 0,
          google_display: onlineData.google_display || 0,
          sms: onlineData.sms || 0,
          referrals: onlineData.referrals || 0,
          partner_digital: onlineData.partner_digital || 0,
          internal_edms: onlineData.internal_edms || 0,
          bing_search: onlineData.bing_search || 0,
          influencers: onlineData.influencers || 0,
        }).reduce((acc, [key, value]) => {
          if (parseFloat(value) > 0) {
            acc[key] = parseFloat(value);
          }
          return acc;
        }, {});
        
        // Calculate total online budget
        const total = Object.values(filteredOnlineData).reduce((sum, value) => sum + value, 0);
        setTotalOnlineBudget(total);
        setOnlineInvestments(filteredOnlineData);
      }
    } catch (error) {
      console.error('Error fetching online investments:', error);
    }
  };

  // Modify the fetchInvestmentoffline function similarly
  const fetchInvestmentoffline = async (eventName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/post_event_investment_offline/${eventName.trim()}`);
      const offlineData = response.data[0];
      if (offlineData) {
        const filteredOfflineData = Object.entries({
          posters: offlineData.posters || 0,
          metro_radio: offlineData.metro_radio || 0,
          regional_radio: offlineData.regional_radio || 0,
          flyers: offlineData.flyers || 0,
          print_ads: offlineData.print_ads || 0,
          bus_sides: offlineData.bus_sides || 0,
          taxi_backs: offlineData.taxi_backs || 0,
          direct_mail: offlineData.direct_mail || 0,
          pr: offlineData.pr || 0,
          billboard: offlineData.billboard || 0,
          newspaper_ad: offlineData.newspaper_ad || 0,
          newspapers: offlineData.newspapers || 0,
          regional_tv_and_metro_tv: offlineData.regional_tv_and_metro_tv || 0,
          flags: offlineData.flags || 0,
        }).reduce((acc, [key, value]) => {
          if (parseFloat(value) > 0) {
            acc[key] = parseFloat(value);
          }
          return acc;
        }, {});
        
        // Calculate total offline budget
        const total = Object.values(filteredOfflineData).reduce((sum, value) => sum + value, 0);
        setTotalOfflineBudget(total);
        setOfflineInvestments(filteredOfflineData);
      }
    } catch (error) {
      console.error('Error fetching offline investments:', error);
    }
  };


  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Changed from true
    plugins: {
      legend: {
        position: 'right',
        labels: {
          font: {
            family: 'Public Sans',
            size: 12
          },
          boxWidth: 15,
          padding: 15
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value} ${currency} (${percentage}%)`;  // Changed from USD to currency
          }
        }
      }
    }
  };

  
  const getPieData = (investments, title) => {
    // Only create pie data if we have investments
    if (Object.keys(investments).length === 0) {
      return {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
        }]
      };
    }

    const capitalizeWords = (str) => {
      return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };

    const nonZeroInvestments = Object.entries(investments)
      .filter(([_, value]) => value > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    const labels = Object.keys(nonZeroInvestments).map(key => capitalizeWords(key));
    const data = Object.values(nonZeroInvestments);
    
    const colorPalette = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
      '#FF9F40', '#FF6384', '#C9CBCF', '#7BC225', '#B97EBB'
    ];

    return {
      labels,
      datasets: [{
        label: title,
        data,
        backgroundColor: labels.map((_, index) => colorPalette[index % colorPalette.length]),
      }],
    };
  };

  const handleMetricsChange = (e) => {
    const { name, value } = e.target;
    // Only allow numbers and decimal points
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setMetrics(prevMetrics => ({ ...prevMetrics, [name]: value }));
    }
  };
  
  const handleSubmitMetrics = async () => {
    // Check if all metrics are filled
    if (!metrics.overallRevenue || !metrics.averageOrderValue || !metrics.totalRegistrations) {
      alert('Please fill in all metrics fields before proceeding.');
      return;
    }
  
    try {
      // First save the metrics
      const postData = {
        overallRevenue: parseFloat(metrics.overallRevenue),
        averageOrderValue: parseFloat(metrics.averageOrderValue),
        totalRegistrations: parseInt(metrics.totalRegistrations)
      };
  
      // Save metrics to database
      await axios.post(
        `${process.env.REACT_APP_API_URL}/save-metrics/${eventName}`, 
        postData
      );
  
      // If successful, navigate to the next page
      navigate('/eventlist', {
        state: {
          eventName,
          userEmail,
          currency 
        }
      });
    } catch (error) {
      console.error('Error saving metrics:', error);
      alert('Error saving metrics. Please try again.');
    }
  };

  const handleBack = () => {
    navigate('/eventtable', {
      state: {
        eventName,
        userEmail,
        onlineInvestments,
        offlineInvestments,
        currency
      }
    });
  };

  const handleSave = async () => {
    if (!metrics.overallRevenue || !metrics.averageOrderValue || !metrics.totalRegistrations) {
      alert('Please fill in all metrics fields before saving.');
      return;
    }
  
    try {
      // Format the data to match backend expectations
      const postData = {
        overallRevenue: parseFloat(metrics.overallRevenue),
        averageOrderValue: parseFloat(metrics.averageOrderValue),
        totalRegistrations: parseInt(metrics.totalRegistrations)
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/save-metrics/${eventName}`, 
        postData
      );
  
      if (response.data.message) {
        alert('Metrics saved successfully!');
        // Optionally refresh the metrics data
        await fetchMetrics(eventName, userEmail);
      }
    } catch (error) {
      console.error('Error saving metrics:', error);
      alert('Error saving metrics. Please try again.');
    }
  };

  if (!metricsLoaded) {
    return <div>Loading metrics...</div>;
  }

  return (
    <div className="event-details">
      <div className="breadcrumb">
        <span>Event Lists</span>
        <span className="separator">›</span>
        <span>{eventName}</span>
      </div>

      <h1>Budget Allocation Existing Campaign</h1>
      
      {/* Online/Offline Budget Summary */}
      <div className="budget-summary">
        <div className="online-budget">
          <h3>Online</h3>
          <div className="budget-amount">{totalOnlineBudget.toLocaleString()} {currency}</div>
          <div className="budget-label">Platforms</div>
        </div>
        <div className="offline-budget">
          <h3>Offline</h3>
          <div className="budget-amount">{totalOfflineBudget.toLocaleString()} {currency}</div>
          <div className="budget-label">Platforms</div>
        </div>
      </div>

      {/* Charts */}
      <div className="chart-wrapper">
        <div className="chart-container">
          <h3>Online Platforms</h3>
          <div className="chart-inner-container">
            <Pie 
              data={getPieData(onlineInvestments, 'Online Platforms')} 
              options={chartOptions}
            />
          </div>
        </div>
        <div className="chart-container">
          <h3>Offline Platforms</h3>
          <div className="chart-inner-container">
            <Pie 
              data={getPieData(offlineInvestments, 'Offline Platforms')} 
              options={chartOptions}
            />
          </div>
        </div>
      </div>

      {/* Metrics Section */}
      <div className="metrics-overview">
        <h2>Metrics Overview</h2>
        <p>Please fill them in here to receive a detailed strategy</p>
        <div className="metrics-row">
          <div className="metric-field">
            <label htmlFor="overallRevenue">
              Overall Revenue ({currency})
            </label>
            <input
              id="overallRevenue"
              type="text"
              name="overallRevenue"
              value={metrics.overallRevenue}
              onChange={handleMetricsChange}
            />
          </div>

          <div className="metric-field">
            <label htmlFor="averageOrderValue">
              Average Order Value ({currency})
            </label>
            <input
              id="averageOrderValue"
              type="text"
              name="averageOrderValue"
              value={metrics.averageOrderValue}
              onChange={handleMetricsChange}
            />
          </div>

          <div className="metric-field">
            <label htmlFor="totalRegistrations">
              Total Registrations
            </label>
            <input
              id="totalRegistrations"
              type="text"
              name="totalRegistrations"
              value={metrics.totalRegistrations}
              onChange={handleMetricsChange}
            />
          </div>
        </div>


      </div>
      {/* Notes Section */}
      <NotesSection 
        eventName={eventName} 
        eventType="post"
      />

      {/* Buttons */}
      <div className="button-container">
        <button className="back-button" onClick={handleBack}>
          Back
        </button>
        <button 
          className="plan-next-event-button"
          onClick={handleSubmitMetrics}
          disabled={!metrics.overallRevenue || !metrics.averageOrderValue || !metrics.totalRegistrations}
        >
          Plan Your Next Event
        </button>
      </div>
    </div>
  );
};

export default EventDetails;