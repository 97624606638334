import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../MainPage/typography.css';
import './EventForm.css';
import moment from 'moment-timezone';

function PreEventForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.userEmail;

  const isPreEvent = location.state?.eventPhase === 'pre';

  const [formData, setFormData] = useState({
    eventName: '',
    eventStartDate: '',
    eventEndDate: '',
    eventCategory: '',
    eventSubCategory: '',
    eventObjective: 'Visitors',
    total_marketing_budget: '',
    targetTicketSales: '',
    targetRevenue: '',
    eventLocation: '',
    companyName: '',
    averageTicketPrice: '',
    timezone: '',
    currency: 'USD',
    platforms: {
      offline: [],
      online: []
    }
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [showSubCategories, setShowSubCategories] = useState(false);


  const offlinePlatforms = ['Posters', 'Metro_Radio', 'Regional_Radio', 'Flyers', 'Print_Ads', 'Bus_Sides', 'Taxi_Backs', 'Direct_Mail', 'PR', 'Billboard', 'Newspaper_Ad','Regional_TV_And_Metro_TV','Newspapers','Flags'];
  const onlinePlatforms = ['Tiktok','Facebook','Instagram','Linkedin','Google_Demand_Gen','Google_Pmax','Google_Search','Google_Display','SMS', 'Referrals', 'Partner_Digital', 'Internal_EDMS','Bing_Search','Influencers'];

  const eventCategories = {
    B2B: ['Trade Shows', 'Conferences', 'Networking Events', 'Seminars', 'Workshops'],
    B2C: ['Exhibitions', 'Concerts', 'Festivals', 'Sports Events', 'Cultural Events']
  };

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventName = location.state?.eventName || location.state?.existingEventName;
        if (!eventName) return;

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pre_event_details/${eventName}`);
        const eventData = response.data;
        
        // Get existing investment data to check which platforms have values
        const [offlineInvestments, onlineInvestments] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/pre_event_investment_offline/${eventName}`),
          axios.get(`${process.env.REACT_APP_API_URL}/pre_event_investment_online/${eventName}`)
        ]);

        // Create sets of platforms that have investments
        const platformsWithOfflineInvestments = new Set(
          Object.entries(offlineInvestments.data[0] || {})
            .filter(([key, value]) => value > 0 && key !== 'event_name' && key !== 'totalinvestmentoffline')
            .map(([key]) => key)
        );

        const platformsWithOnlineInvestments = new Set(
          Object.entries(onlineInvestments.data[0] || {})
            .filter(([key, value]) => value > 0 && key !== 'event_name' && key !== 'totalinvestmentonline')
            .map(([key]) => key)
        );

        // Combine existing selected platforms with platforms that have investments
        // Normalize platform names to match exactly with offlinePlatforms/onlinePlatforms arrays
        const selectedOfflinePlatforms = new Set([
          ...(eventData.offline_platforms || []).map(p => p),
          ...Array.from(platformsWithOfflineInvestments).map(p => p)
        ]);

        const selectedOnlinePlatforms = new Set([
          ...(eventData.online_platforms || []).map(p => p),
          ...Array.from(platformsWithOnlineInvestments).map(p => p)
        ]);

        setFormData({
          ...formData,
          eventName: eventData.event_name,
          eventStartDate: new Date(eventData.event_start_date).toISOString().split('T')[0],
          eventEndDate: new Date(eventData.event_end_date).toISOString().split('T')[0],
          eventCategory: eventData.event_category || '',
          eventSubCategory: eventData.event_sub_category || '',
          total_marketing_budget: eventData.total_marketing_budget || '',
          targetTicketSales: eventData.target_ticket_sales || '',
          eventObjective: eventData.event_objective || 'Visitors',
          targetRevenue: eventData.target_revenue || '',
          averageTicketPrice: eventData.average_ticket_price || '',
          eventLocation: eventData.event_location || '',
          companyName: eventData.company_name || '',
          timezone: eventData.timezone || '',
          currency: eventData.currency || 'USD',
          platforms: {
            offline: Array.from(selectedOfflinePlatforms),
            online: Array.from(selectedOnlinePlatforms)
          }
        });

      } catch (error) {
        console.error('Error fetching event data:', error);
        setErrorMessage('Error fetching event data. Please try again.');
      }
    };

    if (location.state?.isEditing) {
      fetchEventData();
    }
  }, [location.state]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    
    // Convert empty strings to '0' for numeric fields
    if (value === '' && ['total_marketing_budget', 'targetTicketSales', 'targetRevenue', 'averageTicketPrice'].includes(name)) {
      updatedValue = '0';
    }
    
    setFormData(prevData => ({ ...prevData, [name]: updatedValue }));
  };
  <div className="form-group">
  <label htmlFor="total_marketing_budget">Total Marketing Budget (Excluding GST) *</label>
  <input
    type="number"
    id="total_marketing_budget"
    name="total_marketing_budget"  // Changed from total_marketing_budget
    value={formData.total_marketing_budget}
    onChange={handleInputChange}
    min="0"
    required
  />
</div>

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({ 
      ...prevData, 
      eventCategory: value,
      eventSubCategory: '' 
    }));
  };

  const handleSubCategoryChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({ ...prevData, eventSubCategory: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: checked }));
  };

  const handlePlatformChange = (type, platform) => {
    setFormData(prevData => {
      const updatedPlatforms = { ...prevData.platforms };
      if (updatedPlatforms[type].includes(platform)) {
        updatedPlatforms[type] = updatedPlatforms[type].filter(p => p !== platform);
      } else {
        updatedPlatforms[type] = [...updatedPlatforms[type], platform];
      }
      return { ...prevData, platforms: updatedPlatforms };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      const dataToSend = {
        ...formData,
        userEmail,
        total_marketing_budget: parseFloat(formData.total_marketing_budget || 0),
        targetTicketSales: parseInt(formData.targetTicketSales || 0),
        targetRevenue: parseFloat(formData.targetRevenue || 0),
        averageTicketPrice: parseFloat(formData.averageTicketPrice || 0)
      };
  
      console.log('Form Data before send:', formData);
      console.log('Data being sent to backend:', dataToSend);
  
      let response;
      if (location.state?.isEditing) {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/update_pre_event/${formData.eventName}`, 
          dataToSend
        );
        console.log('Update response:', response.data);
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create_pre_event`, 
          dataToSend
        );
        console.log('Create response:', response.data);
      }
  
      localStorage.setItem('eventFormData', JSON.stringify(formData));
      navigate('/preeventtable', { 
        state: { 
          eventName: formData.eventName, 
          userEmail, 
          platforms: formData.platforms,
          phase: 'pre',
          isEditing: location.state?.isEditing,
          currency: formData.currency 
        } 
      });
    } catch (error) {
      console.error('Error:', error.response?.data || error);
      setErrorMessage(
        <span className="error-text">
          {error.response?.data?.error || 'Error updating event. Please try again.'}
        </span>
      );
    }
  };


  const timezones = moment.tz.names();

  const groupedTimezones = timezones.reduce((acc, tz) => {
    const [continent, ...rest] = tz.split('/');
    if (!acc[continent]) {
      acc[continent] = [];
    }
    acc[continent].push(tz);
    return acc;
  }, {});

  const formatPlatformName = (name) => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  };

  return (
    <div className="pre-event-form-container">
      <header className="event-form-header">
        <div className="event-breadcrumb">
          <span>Event Lists</span>
          <span className="separator">›</span>
          <span>{location.state?.isEditing ? 'Edit Pre-Event Strategy' : 'Create New Pre Event'}</span>
        </div>
      </header>
      <main>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="event-form-row">
            <div className="event-form-group">
              <label htmlFor="eventName">
                Event Name<span className="required">*</span>
              </label>
              <input
                type="text"
                id="eventName"
                name="eventName"
                value={formData.eventName}
                onChange={handleInputChange}
                disabled={location.state?.isEditing}
                required
              />
            </div>
            <div className="event-form-group">
              <label htmlFor="eventStartDate">
                Event Start Date<span className="required">*</span>
              </label>
              <div className="date-input-wrapper">
                <input
                  type="date"
                  id="eventStartDate"
                  name="eventStartDate"
                  value={formData.eventStartDate}
                  onChange={handleInputChange}
                  required
                />

              </div>
            </div>

            <div className="event-form-group">
              <label htmlFor="eventEndDate">
                Event End Date<span className="required">*</span>
              </label>
              <div className="date-input-wrapper">
                <input
                  type="date"
                  id="eventEndDate"
                  name="eventEndDate"
                  value={formData.eventEndDate}
                  onChange={handleInputChange}
                  required
                />

              </div>
            </div>
          </div>

          <div className="event-form-row">
            <div className="event-form-group">
              <label htmlFor="eventCategory">Event Category *</label>
              <select
                id="eventCategory"
                name="eventCategory"
                value={formData.eventCategory}
                onChange={handleCategoryChange}
                required
              >
                <option value="">Select Category</option>
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
              </select>
            </div>

            <div className="event-form-group">
              <label htmlFor="eventSubCategory">Event Sub-Category *</label>
              <select
                id="eventSubCategory"
                name="eventSubCategory"
                value={formData.eventSubCategory}
                onChange={handleSubCategoryChange}
                required
                disabled={!formData.eventCategory}
              >
                <option value="">Select Sub-Category</option>
                {formData.eventCategory && eventCategories[formData.eventCategory]?.map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </div>

                        
            <div className="event-form-group">
              <label htmlFor="eventObjective">Event Objective *</label>
              <select
                id="eventObjective"
                name="eventObjective"
                value={formData.eventObjective}
                onChange={handleInputChange}
                required
              >
                <option value="Visitors">Visitors</option>
                <option value="Ticket Sales">Ticket Sales</option>
              </select>
            </div>


            </div>

            <div className="event-form-row">

            <div className="event-form-group">
              <label htmlFor="total_marketing_budget">Total Marketing budget (Excluding GST) *</label>
              <input
                type="number"
                id="total_marketing_budget"
                name="total_marketing_budget"
                value={formData.total_marketing_budget}
                onChange={handleInputChange}
                min="0"
                required
              />
            </div>
            <div className="event-form-group">
              <label htmlFor="targetTicketSales">
                Target Ticket Sales/Registrations<span className="required">*</span>
              </label>
              <input
                type="number"
                id="targetTicketSales"
                name="targetTicketSales"
                value={formData.targetTicketSales}
                onChange={handleInputChange}
                required
              />
            </div>
       
            


            <div className="event-form-group">
              <label htmlFor="targetRevenue">Target Revenue from Ticket sales*</label>
              <input
                type="number"
                id="targetRevenue"
                name="targetRevenue"
                value={formData.targetRevenue}
                onChange={handleInputChange}
                required
              />
            </div>
            </div>

            <div className="event-form-row">
            <div className="event-form-group">
              <label htmlFor="eventLocation">Event Location *</label>
              <input
                type="text"
                id="eventLocation"
                name="eventLocation"
                value={formData.eventLocation}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div className="event-form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
              />
            </div>

            <div className="event-form-group">
              <label htmlFor="averageTicketPrice">
                Average Ticket Price<span className="required">*</span>
              </label>
              <input
                type="number"
                id="averageTicketPrice"
                name="averageTicketPrice"
                value={formData.averageTicketPrice}
                onChange={handleInputChange}
                required
              />
            </div>


            </div>

            <div className="event-form-row">



             <div className="event-form-group">
              <label htmlFor="timezone">Set Timezone *</label>
              <select
                id="timezone"
                name="timezone"
                value={formData.timezone}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a timezone</option>
                {Object.entries(groupedTimezones).map(([continent, zones]) => (
                  <optgroup key={continent} label={continent}>
                    {zones.map((tz) => (
                      <option key={tz} value={tz}>
                        {tz} ({moment.tz(tz).format('Z')})
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>

            <div className="event-form-group">
              <label htmlFor="currency">Set Currency *</label>
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                required
              >
                <option value="USD">USD</option>
                <option value="AUD">AUD</option>
                <option value="NZD">NZD</option>
                <option value="GBP">GBP</option>
                <option value="Euro">Euro</option>
              </select>
            </div>


            
            </div>
          <div className="event-form-row">
            <div className="event-form-group full-width">
              <h3>Platforms</h3>
              <div className="platforms-container">
                <div className="platform-group">
                  <h4>Offline</h4>
                  <div className="platform-options">
                    {offlinePlatforms.map((platform) => (
                      <button
                        key={platform}
                        type="button"
                        className={formData.platforms.offline.includes(platform) ? 'active' : ''}
                        onClick={() => handlePlatformChange('offline', platform)}
                      >
                        {formatPlatformName(platform)}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="platform-group">
                  <h4>Online</h4>
                  <div className="platform-options">
                    {onlinePlatforms.map((platform) => (
                      <button
                        key={platform}
                        type="button"
                        className={formData.platforms.online.includes(platform) ? 'active' : ''}
                        onClick={() => handlePlatformChange('online', platform)}
                      >
                        {formatPlatformName(platform)}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button type="submit" className="btn-next">Next</button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default PreEventForm;