import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NotesSection.css';

const NotesSection = ({ eventName, eventType }) => {
  const [notes, setNotes] = useState('');
  const [isEditing, setIsEditing] = useState(true); // Start in editing mode by default
  const [isSaving, setIsSaving] = useState(false);
  const [hasExistingNotes, setHasExistingNotes] = useState(false);

  useEffect(() => {
    fetchNotes();
  }, [eventName, eventType]);

  const fetchNotes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-notes/${eventName}/${eventType}`
      );
      
      // If we get existing notes, update the editing state
      if (response.data.notes) {
        setNotes(response.data.notes);
        setIsEditing(false); // Disable editing for existing notes
        setHasExistingNotes(true);
      } else {
        setNotes('');
        setIsEditing(true); // Keep editing enabled for new notes
        setHasExistingNotes(false);
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
      // If there's an error, assume it's a new note
      setIsEditing(true);
      setHasExistingNotes(false);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/save-notes/${eventName}`, {
        notes,
        eventType
      });
      setIsEditing(false);
      setHasExistingNotes(true);
    } catch (error) {
      console.error('Error saving notes:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="notes-section">
      <div className="notes-header">
        <h3>Notes</h3>
        {!isEditing ? (
          <button onClick={() => setIsEditing(true)} className="edit-button">
            Edit Notes
          </button>
        ) : (
          <div className="button-group">
            <button onClick={handleSave} className="save-button" disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            {hasExistingNotes && (
              <button 
                onClick={() => {
                  setIsEditing(false);
                  fetchNotes(); // Reset to original notes
                }} 
                className="cancel-button"
              >
                Cancel
              </button>
            )}
          </div>
        )}
      </div>
      
      {isEditing ? (
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Add your notes here..."
          className="notes-textarea"
          autoFocus // Automatically focus when in editing mode
        />
      ) : (
        <div className="notes-content">
          {notes || 'No notes added yet.'}
        </div>
      )}
    </div>
  );
};

export default NotesSection;