import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import NotesSection from './NotesSection';
import './PreEventDetails.css';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const PreEventDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { eventName, userEmail, offlineInvestments, onlineInvestments, currency = 'USD' } = location.state || {};

  const calculateTotal = (investments) => {
    if (!investments) return 0;
    return Object.values(investments)
      .reduce((sum, value) => sum + parseFloat(value || 0), 0)
      .toFixed(0);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          boxWidth: 12
        }
      }
    }
  };

  const getPieData = (investments, title) => {
    if (!investments || Object.keys(investments).length === 0) {
      return {
        labels: [],
        datasets: [{
          label: title,
          data: [],
          backgroundColor: [],
        }],
      };
    }

    const nonZeroInvestments = Object.entries(investments)
      .filter(([_, value]) => parseFloat(value) > 0)
      .reduce((acc, [key, value]) => {
        const formattedKey = key
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
        acc[formattedKey] = parseFloat(value);
        return acc;
      }, {});

    const labels = Object.keys(nonZeroInvestments);
    const data = Object.values(nonZeroInvestments);
    const colorPalette = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
      '#FF9F40', '#FF6384', '#C9CBCF', '#7BC225', '#B97EBB'
    ];

    return {
      labels,
      datasets: [{
        label: title,
        data,
        backgroundColor: labels.map((_, index) => colorPalette[index % colorPalette.length]),
      }],
    };
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    try {
      alert('Pre-event strategy saved successfully!');
      navigate('/eventlist', {
        state: {
          userEmail
        }
      });
    } catch (error) {
      console.error('Error saving strategy:', error);
      alert('Failed to save strategy');
    }
  };

  return (
    <div className="pre-event-details-container">
      <header className="pre-event-details-header">
        <div className="pre-event-details-breadcrumb">
          <span>Event Lists</span>
          <span className="separator">›</span>
          <span>{eventName}</span>
        </div>
      </header>

      <div className="pre-event-details-content">
        <h2>Target Budget Allocation</h2>
        
        <div className="pre-event-charts-grid">
          <div className="pre-event-chart-card">
            <div className="pre-event-chart-header">
              <h3>Online</h3>
              <div className="pre-event-total">
                {calculateTotal(onlineInvestments)} {currency}
              </div>
              <div className="pre-event-subheader">Platforms</div>
            </div>
            <div className="pre-event-chart-wrapper">
              {Object.keys(onlineInvestments || {}).some(key => parseFloat(onlineInvestments[key]) > 0) ? (
                <Pie 
                  data={getPieData(onlineInvestments, 'Online Platforms')} 
                  options={chartOptions}
                />
              ) : (
                <div className="no-data-message">No online investment data available</div>
              )}
            </div>
          </div>
          
          <div className="pre-event-chart-card">
            <div className="pre-event-chart-header">
              <h3>Offline</h3>
              <div className="pre-event-total">
                {calculateTotal(offlineInvestments)} {currency}
              </div>
              <div className="pre-event-subheader">Platforms</div>
            </div>
            <div className="pre-event-chart-wrapper">
              {Object.keys(offlineInvestments || {}).some(key => parseFloat(offlineInvestments[key]) > 0) ? (
                <Pie 
                  data={getPieData(offlineInvestments, 'Offline Platforms')} 
                  options={chartOptions}
                />
              ) : (
                <div className="no-data-message">No offline investment data available</div>
              )}
            </div>
          </div>
        </div>

        <NotesSection 
          eventName={eventName} 
          eventType="pre"
        />

        <div className="pre-event-details-actions">
          <button className="pre-event-btn-back" onClick={handleBack}>Back</button>
          <button className="pre-event-btn-save" onClick={handleSave}>Save Strategy</button>
        </div>
      </div>
    </div>
  );
};

export default PreEventDetails;