import React, { useState, useEffect, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'chart.js/auto';
import './Popup.css';
import { useLocation, useNavigate } from 'react-router-dom';

const ViewEventPopup = ({ event, viewType, onClose }) => {
  const [onlineInvestments, setOnlineInvestments] = useState({});
  const [offlineInvestments, setOfflineInvestments] = useState({});
  const [eventDetails, setEventDetails] = useState({
    currency: event?.currency || 'USD'
  });
  const [metricsDetails, setMetricsDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const pdfContentRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const userEmail = location.state?.userEmail;
  const [summary, setSummary] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        console.log('ViewEventPopup: Fetching data for type:', viewType);
        const eventType = viewType === 'post' ? 'post' : 'pre';
        
        // Log the event name and type for debugging
        console.log('Event Name:', event.event_name);
        console.log('Event Type:', eventType);
  
        const onlineEndpoint = `${process.env.REACT_APP_API_URL}/${eventType}_event_investment_online/${event.event_name}`;
        const offlineEndpoint = `${process.env.REACT_APP_API_URL}/${eventType}_event_investment_offline/${event.event_name}`;
        
        // Log the endpoints
        console.log('Online Endpoint:', onlineEndpoint);
        console.log('Offline Endpoint:', offlineEndpoint);
  
        const [onlineResponse, offlineResponse] = await Promise.all([
          axios.get(onlineEndpoint),
          axios.get(offlineEndpoint)
        ]);
  
        // Log raw response data
        console.log('Online Response:', onlineResponse.data);
        console.log('Offline Response:', offlineResponse.data);
  
        // Ensure we're getting data and it's properly formatted
        const onlineData = onlineResponse.data[0] || {};
        const offlineData = offlineResponse.data[0] || {};
  
        // Filter and clean the data
        const cleanOnlineData = {};
        const cleanOfflineData = {};
  
        // Process online data
        Object.entries(onlineData).forEach(([key, value]) => {
          if (key !== 'event_name' && key !== 'id' && value !== null && parseFloat(value) > 0) {
            cleanOnlineData[key] = parseFloat(value);
          }
        });
  
        // Process offline data
        Object.entries(offlineData).forEach(([key, value]) => {
          if (key !== 'event_name' && key !== 'id' && value !== null && parseFloat(value) > 0) {
            cleanOfflineData[key] = parseFloat(value);
          }
        });
  
        // Log cleaned data
        console.log('Cleaned Online Data:', cleanOnlineData);
        console.log('Cleaned Offline Data:', cleanOfflineData);
  
        // After your online/offline investment fetches, add:
        const eventDetailsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/${eventType}_event_details/${event.event_name}`);
        const eventData = eventDetailsResponse.data;

        setOnlineInvestments(cleanOnlineData);
        setOfflineInvestments(cleanOfflineData);
        setEventDetails({ 
          ...event, 
          eventType,
          currency: eventData.currency || event.currency || 'USD' 
        });

        if (eventType === 'post') {
          try {
            const metricsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/get_metric/${event.event_name}`);
            if (metricsResponse.data && metricsResponse.data.length > 0) {
              const metricData = metricsResponse.data[0];
              setMetricsDetails({
                overallrevenue: metricData.overallRevenue,
                averageordervalue: metricData.averageOrderValue,
                totalregistrations: metricData.totalRegistrations
              });
            }
          } catch (metricsError) {
            console.error('Error fetching metrics:', metricsError);
          }
        }
  
        // Fetch summary with proper viewType parameter
        const summaryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/event_summary/${event.event_name}`,
          { params: { viewType: eventType } }
        );
        
        console.log('Summary Response:', summaryResponse.data);
        setSummary(summaryResponse.data);
  
        setIsLoading(false);
      } catch (error) {
        console.error('Error in ViewEventPopup:', error);
        setError('Failed to load data');
        setIsLoading(false);
      }
    };
  
    if (event?.event_name && viewType) {
      fetchData();
    }
  }, [event?.event_name, viewType, userEmail]);

  const processChartData = (data) => {
    // Filter out total investment and zero values
    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      if (!key.toLowerCase().includes('total') && parseFloat(value) > 0) {
        acc[key] = parseFloat(value);
      }
      return acc;
    }, {});

    // Calculate total for percentage
    const total = Object.values(filteredData).reduce((sum, value) => sum + value, 0);

    const sortedEntries = Object.entries(filteredData)
    .sort((a, b) => b[1] - a[1]);

    // Prepare labels and data
    const formattedData = Object.entries(filteredData).map(([key, value]) => ({
      label: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      value: value,
      percentage: ((value / total) * 100).toFixed(1)
    }));

    const colorPalette = [
      '#FF6384', // Red
      '#36A2EB', // Blue
      '#FFCE56', // Yellow
      '#4BC0C0', // Teal
      '#9966FF', // Purple
      '#FF9F40', // Orange
      '#EA80FC', // Pink
      '#00E676', // Green
      '#FF4081', // Hot Pink
      '#7C4DFF', // Deep Purple
      '#64B5F6', // Light Blue
      '#FFB74D', // Light Orange
      '#81C784', // Light Green
      '#F06292', // Light Pink
      '#4DD0E1', // Light Cyan
      '#BA68C8', // Light Purple
    ];

    // Prepare labels and data
    const labels = sortedEntries.map(([key]) => 
      key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    );
    const values = sortedEntries.map(([_, value]) => value);
    const percentages = values.map(value => ((value / total) * 100).toFixed(1));

    return {
      labels,
      datasets: [{
        data: values,
        backgroundColor: colorPalette.slice(0, values.length),
        borderColor: Array(values.length).fill('white'),
        borderWidth: 1
      }],
      percentages
    };
  };
// Update the pieOptions in your component
const pieOptions = {
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          const value = context.raw;
          const total = context.dataset.data.reduce((sum, v) => sum + v, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${context.label}: ${value} ${eventDetails.currency} (${percentage}%)`;
        }
      }
    }
  },
  responsive: true,
  maintainAspectRatio: true, // Change this to true
  aspectRatio: 1  // Add this to force a 1:1 aspect ratio
};
  

  const onlineData = processChartData(onlineInvestments);
  const offlineData = processChartData(offlineInvestments);

  const downloadPDF = async () => {
    const content = pdfContentRef.current;
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margins = { top: 40, bottom: 60, left: 40, right: 40 };
    const contentWidth = pdfWidth - margins.left - margins.right;
    const contentHeight = pdfHeight - margins.top - margins.bottom;

    // First page
    const firstPageContent = content.querySelector('.first-page-content');
    const firstPageCanvas = await html2canvas(firstPageContent, {
      scale: 2,
      useCORS: true,
      logging: false,
      windowWidth: contentWidth * 2,
      windowHeight: contentHeight * 2
    });

    const firstPageImgData = firstPageCanvas.toDataURL('image/png');
    const firstPageImgWidth = contentWidth;
    const firstPageImgHeight = (firstPageCanvas.height * firstPageImgWidth) / firstPageCanvas.width;

    pdf.setFontSize(18);
    pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });
    pdf.addImage(
      firstPageImgData,
      'PNG',
      margins.left,
      margins.top + 30,
      firstPageImgWidth,
      firstPageImgHeight
    );

    // Second page
    pdf.addPage();
    pdf.setFontSize(18);
    pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });

    const secondPageContent = content.querySelector('.second-page-content');
    const secondPageCanvas = await html2canvas(secondPageContent, {
      scale: 2,
      useCORS: true,
      logging: false,
      windowWidth: contentWidth * 2,
      windowHeight: contentHeight * 2
    });

    const secondPageImgData = secondPageCanvas.toDataURL('image/png');
    const secondPageImgWidth = contentWidth;
    const secondPageImgHeight = (secondPageCanvas.height * secondPageImgWidth) / secondPageCanvas.width;

    pdf.addImage(
      secondPageImgData,
      'PNG',
      margins.left,
      margins.top + 30,
      secondPageImgWidth,
      secondPageImgHeight
    );

    pdf.save(`${event.event_name}_report.pdf`);
  };

  const handleEdit = () => {
    const currency = eventDetails.currency || event.currency || 'USD';
    if (viewType === 'pre') {
      navigate('/create-preevent', {
        state: {
          eventName: event.event_name,
          userEmail,
          eventPhase: 'pre',
          isEditing: true,
          currency: event.currency 
        }
      });
    } else {
      navigate('/create-postevent', {
        state: {
          eventName: event.event_name,
          userEmail,
          eventPhase: 'post',
          isEditing: true,
          currency: event.currency 
        }
      });
    }
  };

  if (isLoading) {
    return (
      <div className="view-event-loading-overlay">
        <div className="view-event-loading-container">
          <div className="view-event-loading-spinner"></div>
          <div className="view-event-loading-message">
            Generating Your Report
          </div>
          <div className="view-event-loading-submessage">
            Please wait while we analyze the event data...
          </div>
          <div className="view-event-loading-progress">
            <div className="view-event-loading-bar"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="popup-overlay"><div className="popup-content">Error: {error}</div></div>;
  }

  return (
    <div className="popup-overlay">
      <div className="view-event-popup">
        <div ref={pdfContentRef} className="pdf-content">
          <div className="first-page-content">
            <div className="view-event-header">
              <h2 className="view-event-title">
                {event.event_name} - {viewType === 'pre' ? 'Pre-Event Strategy' : 'Post-Event Analysis'}
              </h2>
              
              <div className="view-event-details">
                <p><strong>Location:</strong> {eventDetails.event_location}</p>
                <p>
                  <strong>Event Duration:</strong> {' '}
                  {new Date(eventDetails.event_start_date).toLocaleDateString()} - {' '}
                  {new Date(eventDetails.event_end_date).toLocaleDateString()}
                </p>
                <p><strong>Organizer:</strong> {eventDetails.company_name}</p>
                <p><strong>Objective:</strong> {eventDetails.event_objective}</p>
                
                {eventDetails.eventType === 'post' ? (
                  <>
                    <p><strong>Target Revenue:</strong> {eventDetails.target_revenue} {eventDetails.currency}</p>
                    <p><strong>Hit Target:</strong> {eventDetails.hit_target ? 'Yes' : 'No'}</p>
                  </>
                ) : (
                  <p><strong>Target Revenue:</strong> {eventDetails.target_revenue} {eventDetails.currency}</p>
                )}
              </div>
            </div>

            <div className="view-event-charts">
              {onlineData.labels.length > 0 && (
                <div className="view-event-chart-container">
                  <h3 className="view-event-chart-title">Online Platforms</h3>
                  <div className="view-event-chart-wrapper">
                    <Pie data={onlineData} options={pieOptions} />
                  </div>
                  <div className="view-event-legend">
                    {onlineData.labels.map((label, index) => (
                      <div key={label} className="view-event-legend-item">
                      <span 
                        className="view-event-legend-color" 
                        style={{ 
                          backgroundColor: onlineData.datasets[0].backgroundColor[index],
                          border: '1px solid white'
                        }}
                      ></span>
                      <span>
                        {label}: {onlineData.datasets[0].data[index].toLocaleString()} {eventDetails.currency} 
                        ({onlineData.percentages[index]}%)
                      </span>
                    </div>
                    ))}
                  </div>
                </div>
              )}

              {offlineData.labels.length > 0 && (
                <div className="view-event-chart-container">
                  <h3 className="view-event-chart-title">Offline Platforms</h3>
                  <div className="view-event-chart-wrapper">
                    <Pie data={offlineData} options={pieOptions} />
                  </div>
                  <div className="view-event-legend">
                    {offlineData.labels.map((label, index) => (
                      <div key={label} className="view-event-legend-item">
                        <span 
                          className="view-event-legend-color" 
                          style={{ backgroundColor: offlineData.datasets[0].backgroundColor[index] }}
                        ></span>
                        <span>
                          {label}: {offlineData.datasets[0].data[index]} {eventDetails.currency} 
                          ({offlineData.percentages[index]}%)
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="second-page-content">
          {eventDetails.eventType === 'post' && metricsDetails && (
            <div className="view-event-metrics">
              <h3 className="view-event-section-title">Actual Performance</h3>
              <div className="view-event-metrics-grid">
                <div className="metric-item">
                  <p>
                    <strong>Overall Revenue:</strong>{' '}
                    {metricsDetails.overallrevenue 
                      ? `${Number(metricsDetails.overallrevenue).toLocaleString()} ${eventDetails.currency}`
                      : 'Not available'}
                  </p>
                </div>
                <div className="metric-item">
                  <p>
                    <strong>Average Order Value:</strong>{' '}
                    {metricsDetails.averageordervalue 
                      ? `${Number(metricsDetails.averageordervalue).toLocaleString()} ${eventDetails.currency}`
                      : 'Not available'}
                  </p>
                </div>
                <div className="metric-item">
                  <p>
                    <strong>Total Registrations:</strong>{' '}
                    {metricsDetails.totalregistrations 
                      ? Number(metricsDetails.totalregistrations).toLocaleString()
                      : 'Not available'}
                  </p>
                </div>
              </div>
            </div>
          )}
            
            <div className="view-event-summary">
              <h3 className="view-event-section-title">
                {eventDetails.eventType === 'pre' ? 'Strategy Summary' : 'Event Summary'}
              </h3>
              <p className="view-event-summary-text">{summary}</p>
            </div>
          </div>
        </div>
        
        <div className="view-event-actions">
          <button 
            className="view-event-button view-event-button-download" 
            onClick={downloadPDF}
          >
            Download as PDF
          </button>
          <button 
            className="view-event-button view-event-button-edit" 
            onClick={handleEdit}
          >
            <i className="fas fa-edit"></i> Edit {viewType === 'pre' ? 'Strategy' : 'Results'}
          </button>
          <button 
            className="view-event-button view-event-button-close" 
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewEventPopup;