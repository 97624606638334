import React, { useState, useEffect, useCallback  } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "./SideBar";
import "./DashBoard.css";
import EventSelectionPopup from '../CreateEventPage/EventSelectionPopup';

// Import your images
import eventImage1 from "../MainPage/Ev1.jpg";
import eventImage2 from "../MainPage/Ev2.jpg";
import sliderImage1 from "../MainPage/Ev2.jpg";
import sliderImage2 from "../MainPage/Ev1.jpg";  // Add more slider images
import sliderImage3 from "../MainPage/Ev2.jpg";  // Add more slider images

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.userEmail;
  
  // Slider states
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const sliderImages = [sliderImage1, sliderImage2, sliderImage3];

  // Event creation popup state
  const [showEventSelectionPopup, setShowEventSelectionPopup] = useState(false);

  const changeSlide = useCallback((direction) => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    if (direction === 'next') {
      setCurrentSlide((prev) => (prev + 1) % sliderImages.length);
    } else {
      setCurrentSlide((prev) => (prev - 1 + sliderImages.length) % sliderImages.length);
    }
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 600);
  }, [isTransitioning, sliderImages.length]);

  // Auto slider
  useEffect(() => {
    const timer = setInterval(() => {
      changeSlide('next');
    }, 5000);

    return () => clearInterval(timer);
  }, [changeSlide]);

  // Handle event creation
  const handleCreateEvent = () => {
    setShowEventSelectionPopup(true);
  };

  const handleEventTypeSelect = (eventType) => {
    setShowEventSelectionPopup(false);
    
    navigate(`/create-${eventType}event`, { 
      state: { 
        userEmail,
        eventPhase: eventType
      } 
    });
  };

  return (
    <div className="dashboard-page">
      <SideBar />
      <div className="dashboard-content">
        <div className="cards-grid">
          <div className="event-card">
            <img src={eventImage1} alt="" className="card-bg" />
            <div className="card-content">
              <h2 className="card-title">Create Your Event</h2>
              <button onClick={handleCreateEvent}>
                Create Events
              </button>
            </div>
          </div>

          <div className="event-card">
            <img src={eventImage2} alt="" className="card-bg" />
            <div className="card-content">
              <h2 className="card-title">Explore Our Upcoming Events</h2>
              <button onClick={() => navigate('/eventlist', { state: { userEmail } })}>
                View Events
              </button>
            </div>
          </div>
        </div>

        <div className="featured-section">
          <div 
            className="slider-container"
            style={{ 
              transform: `translateX(-${currentSlide * 100}%)`
            }}
          >
            {sliderImages.map((image, index) => (
              <div key={index} className="slider-slide">
                <img 
                  src={image} 
                  alt="" 
                  className="slider-image"
                />
              </div>
            ))}
          </div>
          
          <button 
            className="nav-btn left"
            onClick={() => changeSlide('prev')}
            disabled={isTransitioning}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          
          <button 
            className="nav-btn right"
            onClick={() => changeSlide('next')}
            disabled={isTransitioning}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>

        {/* Event Selection Popup */}
        {showEventSelectionPopup && (
          <EventSelectionPopup
            onSelect={handleEventTypeSelect}
            onClose={() => setShowEventSelectionPopup(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;