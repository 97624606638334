import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import './EventComparisonPopup.css';

const EventComparisonPopup = ({ events, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [loadingPhase, setLoadingPhase] = useState('initializing');
  const [eventData, setEventData] = useState([]);
  const [comparisonInsights, setComparisonInsights] = useState('');
  const [activeTab, setActiveTab] = useState('overview');
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    fetchEventDetails();
  }, [events]);

  const fetchEventDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      setLoadingPhase('fetching');
      
      const eventDetails = await Promise.all(
        events.map(async (eventName) => {
          try {
            const [eventInfo, metrics] = await Promise.all([
              axios.get(`${process.env.REACT_APP_API_URL}/event_info/${encodeURIComponent(eventName)}`),
              axios.get(`${process.env.REACT_APP_API_URL}/get_metric/${encodeURIComponent(eventName)}`)
            ]);
            
            const eventData = eventInfo.data['0'] || {};
            return {
              name: eventName,
              '0': eventData,
              ...eventData,
              metrics: metrics.data[0] || {}
            };
          } catch (error) {
            console.error(`Error fetching details for event ${eventName}:`, error);
            return {
              name: eventName,
              error: true,
              metrics: {}
            };
          }
        })
      );
  
      setEventData(eventDetails);
      setLoadingPhase('analyzing');
      
      const validEvents = eventDetails.filter(event => !event.error);
      if (validEvents.length > 0) {
        await generateComparison(validEvents);
      }

      // Add slight delay before hiding loading state
      setTimeout(() => {
        setLoading(false);
      }, 800);

    } catch (error) {
      console.error('Error in fetchEventDetails:', error);
      setError('Failed to fetch event details');
      setLoading(false);
    }
  };

  const generateComparison = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/compare_events`, {
        events: data
      });
      setComparisonInsights(response.data.insights || 'No insights available');
    } catch (error) {
      console.error('Error generating comparison:', error);
      setComparisonInsights('Unable to generate comparison insights at this time.');
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  const getLoadingMessage = () => {
    switch (loadingPhase) {
      case 'initializing':
        return 'Preparing to analyze events...';
      case 'fetching':
        return 'Gathering event information...';
      case 'analyzing':
        return 'Comparing event performance...';
      default:
        return 'Loading...';
    }
  };

  if (loading) {
    return (
      <div className={`event-popup-overlay ${isVisible ? 'visible' : ''}`}>
        <div className="event-popup-modal loading-modal">
          <div className="event-popup-loading">
            <div className="event-popup-spinner"></div>
            <p className="loading-message">{getLoadingMessage()}</p>
            <div className="loading-progress">
              <div 
                className="loading-bar" 
                style={{ 
                  width: loadingPhase === 'initializing' ? '33%' : 
                         loadingPhase === 'fetching' ? '66%' : '100%' 
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const tabs = [
    { id: 'overview', label: 'Overview' },
    { id: 'metrics', label: 'Metrics' },
    { id: 'insights', label: 'AI Insights' }
  ];

  return (
    <div className={`event-popup-overlay ${isVisible ? 'visible' : ''}`}>
      <div className="event-popup-modal">
        <div className="event-popup-header">
          <h2>Event Comparison</h2>
          <button onClick={handleClose} className="event-popup-close">×</button>
        </div>

        <div className="event-popup-tabs">
          {tabs.map(tab => (
            <button
              key={tab.id}
              className={`event-popup-tab ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        <div className="event-popup-content">
          {activeTab === 'overview' && (
            <div className="event-popup-overview">
              {eventData.map((event, index) => (
                <div key={index} className="event-popup-card">
                  <div className="event-popup-card-header">
                    <h3>{event.event_name}</h3>
                  </div>
                  <div className="event-popup-card-body">
                    <div className="event-popup-field">
                      <span>Location</span>
                      <span>{event.event_location || 'N/A'}</span>
                    </div>
                    <div className="event-popup-field">
                      <span>Category</span>
                      <span>{event.event_category || 'N/A'}</span>
                    </div>
                    <div className="event-popup-field">
                      <span>Type</span>
                      <span>{event.event_sub_category || 'N/A'}</span>
                    </div>
                    <div className="event-popup-field">
                      <span>Company</span>
                      <span>{event.company_name || 'N/A'}</span>
                    </div>
                    <div className="event-popup-field">
                      <span>Currency</span>
                      <span>{event.currency || 'USD'}</span>
                    </div>
                    <div className="event-popup-field highlight">
                      <span>Budget</span>
                      <span>${parseFloat(event.total_marketing_budget || 0).toLocaleString()}</span>
                    </div>
                    <div className="event-popup-field highlight">
                      <span>Target Revenue</span>
                      <span>${parseFloat(event.target_revenue || 0).toLocaleString()}</span>
                    </div>
                    <div className="event-popup-field">
                      <span>Target Registrations</span>
                      <span>{event.target_ticket_sales || 'N/A'}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'metrics' && (
            <div className="event-popup-metrics">
              <div className="event-popup-chart-section">
                <h3>Budget & Revenue Comparison</h3>
                <div className="event-popup-chart">
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={eventData.map(event => ({
                        name: event.event_name?.substring(0, 15) + (event.event_name?.length > 15 ? '...' : ''),
                        'Marketing Budget': parseFloat(event.total_marketing_budget || 0),
                        'Total Revenue': parseFloat(event.metrics?.overallrevenue || 0),
                      }))}
                      margin={{ top: 20, right: 30, left: 60, bottom: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        angle={-45}
                        textAnchor="end"
                        interval={0}
                        height={80}
                      />
                      <YAxis />
                      <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
                      <Legend />
                      <Bar dataKey="Marketing Budget" fill="#6366f1" />
                      <Bar dataKey="Total Revenue" fill="#22c55e" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="event-comparison-metrics-grid">
                {eventData.map((event, index) => (
                  <div key={index} className="event-comparison-metrics-card">
                    <div className="event-comparison-metrics-header">
                      <h3>{event.event_name}</h3>
                      <span className="event-comparison-type">{event.event_sub_category}</span>
                    </div>
                    
                    <div className="event-comparison-metrics-content">
                      <div className="event-comparison-metric-group">
                        <h4>Financial Metrics</h4>
                        <div className="event-comparison-metric-row">
                          <div className="event-comparison-metric-item">
                            <label>Marketing Budget</label>
                            <span className="event-comparison-value">
                              ${parseFloat(event.total_marketing_budget || 0).toLocaleString()}
                            </span>
                          </div>
                          <div className="event-comparison-metric-item">
                            <label>Revenue</label>
                            <span className="event-comparison-value">
                              ${parseFloat(event.metrics?.overallrevenue || 0).toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="event-comparison-marketing">
                        <h4>Marketing Channels</h4>
                        <div className="event-comparison-channels">
                          <div className="event-comparison-channel-group">
                            <label>Online Platforms</label>
                            <div className="event-comparison-tags">
                              {event.online_platforms && typeof event.online_platforms === 'string' 
                                ? event.online_platforms
                                    .replace(/[{}"]/g, '')
                                    .split(',')
                                    .map((platform, i) => (
                                      <span key={i} className="event-comparison-tag event-comparison-tag-online">
                                        {platform.trim()}
                                      </span>
                                    ))
                                : 'None'
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'insights' && (
            <div className="event-popup-insights">
              <div className="event-popup-insights-content">
                {comparisonInsights.split('\n').map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventComparisonPopup;