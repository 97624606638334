import React from 'react';
import './EventSelectionPopup.css';

const EventSelectionPopup = ({ onSelect, onClose }) => {
  return (
    <div className="event-selection-overlay">
      <div className="event-selection-popup">
        <div className="popup-header">
          <h2>Create New Event</h2>
          <p>Select the type of data you want to add for your event.</p>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>
        
        {/* Pre-Event Section */}
        <div className="event-section pre-event">
          <div className="section-header">
            <div className="icon-wrapper blue">
              <i className="fas fa-calendar"></i>
            </div>
            <div className="header-text">
              <h3>Pre-Event Planning</h3>
              <p>Plan goals and strategy for upcoming events.</p>
            </div>
          </div>
          
          <div className="features-grid">
            <div className="feature-item">
              <div className="feature-icon blue">
                <i className="fas fa-bullseye"></i>
              </div>
              <p>Set attendance and revenue targets</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon blue">
                <i className="fas fa-dollar-sign"></i>
              </div>
              <p>Plan budget allocation</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon blue">
                <i className="fas fa-brain"></i>
              </div>
              <p>Get event marketing strategy</p>
            </div>
          </div>

          <button 
            className="start-button blue"
            onClick={() => onSelect('pre')}
          >
            Start Pre-Event Planning
          </button>
        </div>

        {/* Post-Event Section */}
        <div className="event-section post-event">
          <div className="section-header">
            <div className="icon-wrapper green">
              <i className="fas fa-chart-pie"></i>
            </div>
            <div className="header-text">
              <h3>Post-Event Analysis</h3>
              <p>Analyze performance, compare targets, gain insights.</p>
            </div>
          </div>

          <div className="features-grid">
            <div className="feature-item">
              <div className="feature-icon green">
                <i className="fas fa-chart-line"></i>
              </div>
              <p>Record actual metrics and results</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon green">
                <i className="fas fa-users"></i>
              </div>
              <p>Track attendance and engagement</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon green">
                <i className="fas fa-chart-bar"></i>
              </div>
              <p>Compare with pre-event targets</p>
            </div>
          </div>

          <button 
            className="start-button green"
            onClick={() => onSelect('post')}
          >
            Start Post-Event Analysis
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventSelectionPopup;