import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './SideBar';
import EventSelectionPopup from '../CreateEventPage/EventSelectionPopup';
import '../MainPage/typography.css';
import './EventList.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ViewEventPopup from './ViewEventPopup';
import EventComparisonPopup from './EventComparisonPopup';
import SingleEventComparison from './SingleEventComparison';
import DeleteEventPopup from './DeleteEventPopup';

const EventList = () => {
  const location = useLocation();
  const userEmail = location.state?.userEmail;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showGuide, setShowGuide] = useState(true);
  const [showEventSelectionPopup, setShowEventSelectionPopup] = useState(false);
  const [showViewStrategyPopup, setShowViewStrategyPopup] = useState(false);
  const [selectedEventForStrategy, setSelectedEventForStrategy] = useState(null);
  const navigate = useNavigate();
  const [showComparisonPopup, setShowComparisonPopup] = useState(false);
  const [showSingleEventComparison, setShowSingleEventComparison] = useState(false);
  const [selectedEventForComparison, setSelectedEventForComparison] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedEventForDeletion, setSelectedEventForDeletion] = useState(null);

  // Cache configuration
  const CACHE_KEY = `events_${userEmail}`;
  const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

  useEffect(() => {
    fetchEvents();
  }, []);

  const getCachedData = () => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      // Check if cache is still valid
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
      // Clear expired cache
      localStorage.removeItem(CACHE_KEY);
    }
    return null;
  };

  const setCachedData = (data) => {
    const cacheData = {
      data,
      timestamp: Date.now()
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  };


  const fetchEvents = async () => {
    try {
      if (!userEmail) {
        navigate('/', { replace: true });
        return;
      }

      // Try to get cached data first
      const cachedEvents = getCachedData();
      if (cachedEvents) {
        setEvents(cachedEvents);
        setLoading(false);
        // Fetch fresh data in background
        fetchFreshData();
        return;
      }

      // If no cache, fetch fresh data
      await fetchFreshData();
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
      navigate('/', { replace: true });
    }
  };

  const fetchFreshData = async () => {
    try {
      const [postEventsResponse, preEventsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/post_event_details_info/${userEmail}`),
        axios.get(`${process.env.REACT_APP_API_URL}/pre_event_details_info/${userEmail}`)
      ]);

      const postEvents = postEventsResponse.data;
      const preEvents = preEventsResponse.data;

      // Process events with post data
      const processedEvents = postEvents.map(postEvent => {
        const matchingPreEvent = preEvents.find(preEvent => preEvent.event_name === postEvent.event_name);
        return {
          ...postEvent,
          phases: {
            post_event: true,
            pre_event: !!matchingPreEvent,
            status: 'Completed'
          }
        };
      });

      // Add pre-only events
      const preOnlyEvents = preEvents
        .filter(preEvent => !postEvents.find(postEvent => postEvent.event_name === preEvent.event_name))
        .map(preEvent => ({
          ...preEvent,
          phases: {
            post_event: false,
            pre_event: true,
            status: 'In Progress'
          }
        }));

      const combinedEvents = [...processedEvents, ...preOnlyEvents];
      setEvents(combinedEvents);
      setCachedData(combinedEvents);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const LoadingSkeleton = () => (
    <div className="events-table">
      <div className="table-header">
        <div>Select</div>
        <div>Event Name</div>
        <div>Location</div>
        <div>Status</div>
        <div>Phase</div>
        <div>Actions</div>
        <div>Delete</div>
      </div>
      {[1, 2, 3, 4, 5].map((index) => (
        <div key={index} className="table-row">
          <div className="select-cell">
            <div className="loading-skeleton w-4 h-4"></div>
          </div>
          <div className="event-name-cell">
            <div className="loading-skeleton h-6 w-3/4"></div>
            <div className="loading-skeleton h-4 w-1/2"></div>
          </div>
          <div className="location-cell">
            <div className="loading-skeleton h-6 w-24"></div>
          </div>
          <div className="status-cell">
            <div className="loading-skeleton h-6 w-20"></div>
          </div>
          <div className="phase-cell">
            <div className="loading-skeleton h-4 w-full"></div>
            <div className="loading-skeleton h-4 w-full"></div>
          </div>
          <div className="actions-cell">
            <div className="loading-skeleton h-8 w-20"></div>
            <div className="loading-skeleton h-8 w-20"></div>
          </div>
          <div className="delete-cell">
            <div className="loading-skeleton h-8 w-8"></div>
          </div>
        </div>
      ))}
    </div>
  );

  const handleCreateEvent = () => {
    setShowEventSelectionPopup(true);
  };

  const handleEventTypeSelect = (eventType) => {
    setShowEventSelectionPopup(false);
    
    navigate(`/create-${eventType}event`, { 
      state: { 
        userEmail,
        eventPhase: eventType
      } 
    });
  };

// Update the handleDeleteEvent function in EventList.js
const handleDeleteEvent = async (eventName, phases) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/delete_event/${eventName}`, {
      data: { phases }
    });

    if (response.status === 200) {
      // The popup will close automatically after showing success message
      await fetchEvents(); // Refresh the events list
    }
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error; // Throw error to be handled by the popup
  }
};
  


  const handleCompareEvents = () => {
    if (selectedEvents.length < 2) {
      alert('Please select at least 2 events to compare');
      return;
    }
    setShowComparisonPopup(true);
  };
  
  const handleViewStrategy = (event, type) => {
    console.log('Viewing strategy:', { event, type });
    
    // Add debug logs
    console.log('Event phases:', event.phases);
    console.log('Requested type:', type);
    console.log('Phase exists:', event.phases[`${type}_event`]);
  
    // Create complete event info with type info
    const eventForStrategy = {
      ...event,
      viewType: type,  // Pass viewType
      isPreEvent: type === 'pre',
      isPostEvent: type === 'post'
    };
  
    console.log('Event for strategy:', eventForStrategy);
  
    setSelectedEventForStrategy(eventForStrategy);
    setShowViewStrategyPopup(true);
  };

  const handleSingleEventCompare = (eventName) => {
    setSelectedEventForComparison(eventName);
    setShowSingleEventComparison(true);
  };
  
  

  const renderActionButtons = (event) => {
    // If both pre and post event data exist (Completed Events)
    if (event.phases?.pre_event && event.phases?.post_event) {
      return (
        <div className="action-buttons">
          <button 
            className="action-button pre"
            onClick={() => handleViewStrategy(event, 'pre')}
          >
            Pre
          </button>
          <button 
            className="action-button post"
            onClick={() => handleViewStrategy(event, 'post')}
          >
            Post
          </button>
          <button 
            className="action-button compare"
            onClick={() => handleSingleEventCompare(event.event_name)}
          >
            Compare
          </button>
        </div>
      );
    }
    
    // If only pre-event exists
    if (event.phases?.pre_event && !event.phases?.post_event) {
      return (
        <div className="action-buttons">
          <button 
            className="action-button view-strategy"
            onClick={() => handleViewStrategy(event, 'pre')}
          >
            View Strategy
          </button>
          <button 
            className="action-button add-results"
            onClick={() => handleAddPostEventData(event)}
          >
            Add Results
          </button>
        </div>
      );
    }
    
    // If only post-event exists
    if (!event.phases?.pre_event && event.phases?.post_event) {
      return (
        <div className="action-buttons">
          <button 
            className="action-button add-pre"
            onClick={() => handleAddPreEventData(event)}
          >
            Add Pre-Event Data
          </button>
          <button 
            className="action-button view-post"
            onClick={() => handleViewStrategy(event, 'post')}
          >
            View Post Strategy
          </button>
        </div>
      );
    }

    return null;
  };

  const handleAddPreEventData = (event) => {
    // If event already has pre-event data, navigate to view/edit it
    if (event.phases?.pre_event) {
      navigate('/pre-event-details', {
        state: {
          eventName: event.event_name,
          userEmail
        }
      });
    } else {
      // Create new pre-event
      navigate('/create-preevent', {
        state: {
          userEmail,
          eventPhase: 'pre',
          existingEventName: event.event_name
        }
      });
    }
  };

  const fetchPlatforms = async (eventName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/event/${eventName}`);
      const { online, offline } = response.data;
      // Use the platforms data...
    } catch (error) {
      console.error('Error fetching platforms:', error);
    }
  };


  const handleAddPostEventData = (event) => {
    // No need to check for pre-event data since we know it exists
    navigate('/create-postevent', {
      state: {
        userEmail,
        eventPhase: 'post',
        existingEventName: event.event_name,
        // Pass any existing pre-event data that might be needed
        preEventData: event
      }
    });
  };

  return (
    <div className="page-container">
      <Sidebar />
      <div className="content-wrapper">
        <div className="event-list-content">
          <div className="event-list-header">
            <div className="title-section">
              <h1>Event Lists</h1>
              <p>Manage and track your event performance</p>
            </div>
            <div className="button-group">
              <button className="create-button" onClick={handleCreateEvent}>
                <i className="fas fa-plus"></i> Create New Event
              </button>
              <button 
                className="compare-button"
                onClick={handleCompareEvents}
                disabled={loading || selectedEvents.length < 2}
              >
                <i className="fas fa-chart-bar"></i> Compare Event
              </button>
            </div>
          </div>

          <div className="scrollable-content">
            {showGuide && (
              <div className="quick-guide">
                <i className="fas fa-info-circle"></i>
                <span>Quick Guide:</span> Select two or more events to compare their marketing performance and budgets.
                <button className="close-guide" onClick={() => setShowGuide(false)}>×</button>
              </div>
            )}

            {loading ? (
              <LoadingSkeleton />
            ) : (

            <div className="events-table">
                  <div className="table-header">
                    <div>Select</div>
                    <div>Event Name</div>
                    <div>Location</div>
                    <div>Status</div>
                    <div>Phase</div>
                    <div>Actions</div>
                    <div>Delete</div>
                  </div>

                  {events.map((event) => (
                    <div className="table-row" key={event.event_name}>
                      <div className="select-cell">
                        <input
                          type="checkbox"
                          checked={selectedEvents.includes(event.event_name)}
                          onChange={() => {
                            setSelectedEvents(prev => 
                              prev.includes(event.event_name)
                                ? prev.filter(e => e !== event.event_name)
                                : [...prev, event.event_name]
                            );
                          }}
                        />
                      </div>
                      <div className="event-name-cell">
                        <div className="event-title">{event.event_name}</div>
                        <div className="event-date">{event.event_date}</div>
                      </div>
                      <div className="location-cell">{event.event_location}</div>
                      <div className="status-cell">
                        <span className={`status-badge ${event.phases?.status.toLowerCase().replace(' ', '-')}`}>
                          {event.phases?.status || 'In Progress'}
                        </span>
                      </div>
                      <div className="phase-cell">
                        <div className={`phase-item ${event.phases?.pre_event ? 'checked' : ''}`}>
                          <span className={event.phases?.pre_event ? 'phase-check' : 'phase-circle'}></span>
                          <span>Pre-Event</span>
                        </div>
                        <div className={`phase-item ${event.phases?.post_event ? 'checked' : ''}`}>
                          <span className={event.phases?.post_event ? 'phase-check' : 'phase-circle'}></span>
                          <span>Post-Event</span>
                        </div>
                      </div>
                      <div className="actions-cell">
                        {renderActionButtons(event)}
                      </div>

                      <div className="delete-cell">
                        <button 
                          className="delete-event-button"
                          onClick={() => {
                            setSelectedEventForDeletion(event);
                            setShowDeletePopup(true);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
            )}
                </div>
           


          {showEventSelectionPopup && (
            <EventSelectionPopup
              onSelect={handleEventTypeSelect}
              onClose={() => setShowEventSelectionPopup(false)}
            />
          )}

          {showViewStrategyPopup && selectedEventForStrategy && (
                <ViewEventPopup
                  event={selectedEventForStrategy}
                  viewType={selectedEventForStrategy.viewType} // Pass viewType separately
                  onClose={() => {
                    setShowViewStrategyPopup(false);
                    setSelectedEventForStrategy(null);
                  }}
                />
              )}


          {showComparisonPopup && (
            <EventComparisonPopup
              events={selectedEvents}
              onClose={() => setShowComparisonPopup(false)}
            />
          )}


          {showSingleEventComparison && selectedEventForComparison && (
            <SingleEventComparison
              eventName={selectedEventForComparison}
              onClose={() => {
                setShowSingleEventComparison(false);
                setSelectedEventForComparison(null);
              }}
            />
          )}

          {showDeletePopup && selectedEventForDeletion && (
            <DeleteEventPopup
              event={selectedEventForDeletion}
              onClose={() => {
                setShowDeletePopup(false);
                setSelectedEventForDeletion(null);
              }}
              onConfirm={handleDeleteEvent}
            />
          )}



<div className="guide-section">
  <h3>Quick Guide</h3>
  <div className="guide-grid">
    <div className="guide-card">
      <h4>Events with Pre-Event Only:</h4>
      <div className="guide-items">
        <div className="guide-item">
          <strong>View Strategy:</strong> See Event marketing plan
        </div>
        <div className="guide-item">
          <strong>Add Results:</strong> Input post-event data
        </div>
      </div>
    </div>

    <div className="guide-card">
      <h4>Completed Events:</h4>
      <div className="guide-items">
        <div className="guide-item">
          <strong>Pre:</strong> View initial strategy
        </div>
        <div className="guide-item">
          <strong>Post:</strong> View actual results
        </div>
        <div className="guide-item">
          <strong>Compare:</strong> See strategy vs results
        </div>
      </div>
    </div>

    <div className="guide-card">
      <h4>Events with Post-Event Only:</h4>
      <div className="guide-items">
        <div className="guide-item">
          <strong>Add Pre-Event Data:</strong> Start planning
        </div>
        <div className="guide-item">
          <strong>View Results:</strong> See Event marketing plan
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
    </div>
  );
};

export default EventList;