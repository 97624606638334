import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SideBar.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.userEmail;
  const currentPath = location.pathname.slice(1);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Get current page title for breadcrumb
  const getCurrentPageTitle = () => {
    switch(currentPath) {
      case 'dashboard':
        return 'Dashboard';
      case 'eventlist':
        return 'Event Lists';
      case 'profile':
        return 'Settings';
      default:
        return 'Dashboard';
    }
  };

  const handleNavigation = (path) => {
    navigate(`/${path}`, { state: { userEmail } });
    setIsMobileMenuOpen(false); // Close menu after navigation
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    // Prevent body scroll when menu is open
    document.body.style.overflow = !isMobileMenuOpen ? 'hidden' : 'auto';
  };

  // Cleanup overflow style on unmount
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Close menu on window resize (if screen becomes larger)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
        document.body.style.overflow = 'auto';
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileMenuOpen]);

  return (
    <>
      <div className="top-banner">
        {/* Logo */}
        <div className="logo-area">
          <img src="half-eaten-donut.png" alt="Half Eaten Donut" />
        </div>

        {/* Mobile Breadcrumb */}
        <div className="mobile-breadcrumb">
          {getCurrentPageTitle()}
        </div>

        {/* Mobile Menu Toggle */}
        <button 
          className={`mobile-menu-toggle ${isMobileMenuOpen ? 'active' : ''}`}
          onClick={handleMobileMenuToggle}
          aria-label="Toggle menu"
        >
          <i className={`fas ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
        </button>
      </div>

      {/* Sidebar Navigation */}
      <div className={`sidebar ${isMobileMenuOpen ? 'active' : ''}`}>
        <nav className="nav-menu">
          {/* Dashboard */}
          <button 
            className={`nav-item ${currentPath === 'dashboard' ? 'active' : ''}`}
            onClick={() => handleNavigation('dashboard')}
          >
            <i className="fas fa-home"></i>
            Dashboard
          </button>

          {/* Event Lists */}
          <button 
            className={`nav-item ${currentPath === 'eventlist' ? 'active' : ''}`}
            onClick={() => handleNavigation('eventlist')}
          >
            <i className="far fa-calendar"></i>
            Event Lists
          </button>
        </nav>

        {/* Bottom Menu */}
        <div className="bottom-menu">
          {/* Settings */}
          <button 
            className={`nav-item ${currentPath === 'profile' ? 'active' : ''}`}
            onClick={() => handleNavigation('profile')}
          >
            <i className="fas fa-cog"></i>
            Settings
          </button>

          {/* Logout */}
          <button 
            className="nav-item"
            onClick={() => {
              // Clear any stored data if needed
              navigate('/');
            }}
          >
            <i className="fas fa-sign-out-alt"></i>
            Logout
          </button>
        </div>
      </div>

      {/* Mobile Overlay */}
      {isMobileMenuOpen && (
        <div 
          className="sidebar-overlay"
          onClick={handleMobileMenuToggle}
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default Sidebar;