

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './EventForm.css';
import moment from 'moment-timezone';

function PostEventForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.userEmail;

  const isPostEvent = location.state?.eventPhase === 'post';

  const [formData, setFormData] = useState({
    eventName: '',
    eventStartDate: '',
    eventEndDate: '',
    eventCategory: '',
    eventSubCategory: '',
    eventObjective: 'Visitors',
    total_marketing_budget: '', // Changed to match backend field name
    targetTicketSales: '',
    targetRevenue: '',
    eventLocation: '',
    companyName: '',
    averageTicketPrice: '',
    hitTarget: false,
    timezone: '',
    currency: 'USD',
    total_attendees: '',
    platforms: {
      offline: [],
      online: []
    }
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [showSubCategories, setShowSubCategories] = useState(false);


  const offlinePlatforms = ['Posters', 'Metro_Radio', 'Regional_Radio', 'Flyers', 'Print_Ads', 'Bus_Sides', 'Taxi_Backs', 'Direct_Mail', 'PR', 'Billboard', 'Newspaper_Ad','Regional_TV_And_Metro_TV','Newspapers','Flags'];
  const onlinePlatforms = ['Tiktok','Facebook','Instagram','Linkedin','Google_Demand_Gen','Google_Pmax','Google_Search','Google_Display','SMS', 'Referrals', 'Partner_Digital', 'Internal_EDMS','Bing_Search','Influencers'];

  const eventCategories = {
    B2B: ['Trade Shows', 'Conferences', 'Networking Events', 'Seminars', 'Workshops'],
    B2C: ['Exhibitions', 'Concerts', 'Festivals', 'Sports Events', 'Cultural Events']
  };

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventName = location.state?.eventName || location.state?.existingEventName;
        if (!eventName) return;

        // Get event details
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/post_event_details/${eventName}`);
        const eventData = response.data[0];
        console.log('Fetched event data:', eventData);

        // Function to normalize platform names to match the format in offlinePlatforms/onlinePlatforms arrays
        const normalizePlatformName = (platform) => {
          // Remove any case sensitivity issues and standardize format
          return platform; // Keep the exact format as received from backend
        };

        // Normalize the platforms from database
        const normalizedOfflinePlatforms = eventData.offline_platforms
          .map(normalizePlatformName)
          .filter(p => offlinePlatforms.includes(p));

        const normalizedOnlinePlatforms = eventData.online_platforms
          .map(normalizePlatformName)
          .filter(p => onlinePlatforms.includes(p));

        console.log('Normalized offline platforms:', normalizedOfflinePlatforms);
        console.log('Normalized online platforms:', normalizedOnlinePlatforms);

        setFormData({
          ...formData,
          eventName: eventData.event_name,
          eventStartDate: new Date(eventData.event_start_date).toISOString().split('T')[0],
          eventEndDate: new Date(eventData.event_end_date).toISOString().split('T')[0],
          eventCategory: eventData.event_category || '',
          eventSubCategory: eventData.event_sub_category || '',
          total_marketing_budget: eventData.total_marketing_budget?.toString() || '',
          targetTicketSales: eventData.target_ticket_sales?.toString() || '',
          eventObjective: eventData.event_objective || 'Visitors',
          targetRevenue: eventData.target_revenue?.toString() || '',
          averageTicketPrice: eventData.average_ticket_price?.toString() || '',
          eventLocation: eventData.event_location || '',
          companyName: eventData.company_name || '',
          hitTarget: eventData.hit_target || false,
          timezone: eventData.timezone || '',
          currency: eventData.currency || 'USD',
          total_attendees: eventData.total_attendees?.toString() || '', 
          platforms: {
            offline: eventData.offline_platforms.map(normalizePlatformName),
            online: eventData.online_platforms.map(normalizePlatformName)
          }
        });

      } catch (error) {
        console.error('Error fetching event data:', error);
        setErrorMessage('Error fetching event data. Please try again.');
      }
    };

    if (location.state?.isEditing) {
      fetchEventData();
    }
  }, [location.state]);

  // Update handlePlatformChange to handle normalized platform names
  const handlePlatformChange = (type, platform) => {
    setFormData(prevData => {
      const updatedPlatforms = { ...prevData.platforms };
      if (updatedPlatforms[type].includes(platform)) {
        updatedPlatforms[type] = updatedPlatforms[type].filter(p => p !== platform);
      } else {
        updatedPlatforms[type] = [...updatedPlatforms[type], platform];
      }
      return { ...prevData, platforms: updatedPlatforms };
    });
  };

  const isPlatformSelected = (platformName) => {
    return formData.platforms.offline.some(p => p === platformName) || 
           formData.platforms.online.some(p => p === platformName);
  };
  
  // Update the renderPlatformButton function
  const renderPlatformButton = (platform, type) => {
    // Check if this exact platform name exists in selected platforms
    const isActive = formData.platforms[type].some(p => p === platform);
    return (
      <button
        key={platform}
        type="button"
        className={isActive ? 'active' : ''}
        onClick={() => handlePlatformChange(type, platform)}
      >
        {formatPlatformName(platform)}
      </button>
    );
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    
    // Handle numeric fields
    if (['total_marketing_budget', 'targetTicketSales', 'targetRevenue', 'averageTicketPrice'].includes(name)) {
      updatedValue = value === '' ? '0' : value;
    }
    
    setFormData(prevData => ({ ...prevData, [name]: updatedValue }));
  };


  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({ 
      ...prevData, 
      eventCategory: value,
      eventSubCategory: '' 
    }));
  };

  const handleSubCategoryChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({ ...prevData, eventSubCategory: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: checked }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      let response;
      const dataToSend = { ...formData, userEmail };
  
      // First check if this is a post-event creation for an existing pre-event
      const isPostEventForPreEvent = location.state?.eventPhase === 'post' && !location.state?.isEditing;
      
      if (location.state?.isEditing) {
        // Editing existing post-event
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/update_post_event/${formData.eventName}`, 
          dataToSend
        );
      } else {
        // Check if the event name exists in post_events table
        try {
          const checkResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/post_event_details/${formData.eventName}`
          );
          
          if (checkResponse.data.length > 0 && !isPostEventForPreEvent) {
            // Only throw error if it's not a post-event for existing pre-event
            setErrorMessage(<span className="error-text">Post Event Name already exists.</span>);
            return;
          }
        } catch (checkError) {
          // If error is 404, event doesn't exist, which is what we want
          if (checkError.response?.status !== 404) {
            console.error('Error checking event existence:', checkError);
            throw checkError;
          }
        }
  
        // Create new post-event
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create_post_event`, 
          dataToSend
        );
      }
  
      if (response.status === 200 || response.status === 201) {
        // Save platform data
        await Promise.all([
          axios.post(
            `${process.env.REACT_APP_API_URL}/save_post_event_offline/${formData.eventName}`,
            {
              offlineInvestments: formData.platforms.offline.reduce((acc, platform) => {
                acc[platform] = 0;
                return acc;
              }, {}),
              totalInvestmentOffline: 0,
              eventName: formData.eventName
            }
          ),
          axios.post(
            `${process.env.REACT_APP_API_URL}/save_post_event_online/${formData.eventName}`,
            {
              onlineInvestments: formData.platforms.online.reduce((acc, platform) => {
                acc[platform] = 0;
                return acc;
              }, {}),
              totalInvestmentOnline: 0,
              eventName: formData.eventName
            }
          )
        ]);
  
        // Save form data and navigate
        localStorage.setItem('eventFormData', JSON.stringify(formData));
        navigate('/eventtable', { 
          state: { 
            eventName: formData.eventName, 
            userEmail, 
            platforms: formData.platforms,  // Add platforms to navigation state
            phase: 'post',
            isEditing: location.state?.isEditing,
            currency: formData.currency
          } 
        });
      }
    } catch (error) {
      console.error('Error in form submission:', error);
      if (error.response?.data?.message) {
        setErrorMessage(<span className="error-text">{error.response.data.message}</span>);
      } else {
        setErrorMessage(<span className="error-text">An error occurred while saving the event. Please try again.</span>);
      }
    }
  };


  const timezones = moment.tz.names();

  const groupedTimezones = timezones.reduce((acc, tz) => {
    const [continent, ...rest] = tz.split('/');
    if (!acc[continent]) {
      acc[continent] = [];
    }
    acc[continent].push(tz);
    return acc;
  }, {});

  const formatPlatformName = (name) => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  };

  return (
    <div className="pre-event-form-container">  {/* Changed from event-form-container */}
      <header className="event-form-header">    {/* Changed from form-header */}
        <div className="event-breadcrumb">      {/* Changed from breadcrumb */}
          <span>Event Lists</span>
          <span className="separator">›</span>
          <span>{location.state?.isEditing ? 'Edit Post-Event Results' : 'Create New Post Event'}</span>
        </div>
      </header>
      <main>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="event-form-row">      {/* Changed from form-row */}
            <div className="event-form-group">   {/* Changed from form-group */}
              <label htmlFor="eventName">
                Event Name<span className="required">*</span>
              </label>
              <input
                type="text"
                id="eventName"
                name="eventName"
                value={formData.eventName}
                onChange={handleInputChange}
                disabled={location.state?.isEditing}
                required
              />
            </div>
            <div className="event-form-group">
              <label htmlFor="eventStartDate">
                Event Start Date<span className="required">*</span>
              </label>
              <div className="date-input-wrapper">
                <input
                  type="date"
                  id="eventStartDate"
                  name="eventStartDate"
                  value={formData.eventStartDate}
                  onChange={handleInputChange}
                  required
                />

              </div>
            </div>

            <div className="event-form-group">
              <label htmlFor="eventEndDate">
                Event End Date<span className="required">*</span>
              </label>
              <div className="date-input-wrapper">
                <input
                  type="date"
                  id="eventEndDate"
                  name="eventEndDate"
                  value={formData.eventEndDate}
                  onChange={handleInputChange}
                  required
                />

              </div>
            </div>
          </div>

          <div className="event-form-row">
            <div className="event-form-group">
              <label htmlFor="eventCategory">Event Category *</label>
              <select
                id="eventCategory"
                name="eventCategory"
                value={formData.eventCategory}
                onChange={handleCategoryChange}
                required
              >
                <option value="">Select Category</option>
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
              </select>
            </div>

            <div className="event-form-group">
              <label htmlFor="eventSubCategory">Event Sub-Category *</label>
              <select
                id="eventSubCategory"
                name="eventSubCategory"
                value={formData.eventSubCategory}
                onChange={handleSubCategoryChange}
                required
                disabled={!formData.eventCategory}
              >
                <option value="">Select Sub-Category</option>
                {formData.eventCategory && eventCategories[formData.eventCategory]?.map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </div>

                        
            <div className="event-form-group">
              <label htmlFor="eventObjective">Event Objective *</label>
              <select
                id="eventObjective"
                name="eventObjective"
                value={formData.eventObjective}
                onChange={handleInputChange}
                required
              >
                <option value="Visitors">Visitors</option>
                <option value="Ticket Sales">Ticket Sales</option>
              </select>
            </div>


            </div>

            <div className="event-form-row">

            <div className="event-form-group">
              <label htmlFor="total_marketing_budget">Total Marketing budget (Excluding GST) *</label>
              <input
                type="number"
                id="total_marketing_budget"
                name="total_marketing_budget"
                value={formData.total_marketing_budget}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="event-form-group">
              <label htmlFor="targetTicketSales">
                Target Ticket Sales/Registrations<span className="required">*</span>
              </label>
              <input
                type="number"
                id="targetTicketSales"
                name="targetTicketSales"
                value={formData.targetTicketSales}
                onChange={handleInputChange}
                required
              />
            </div>
       
            


            <div className="event-form-group">
              <label htmlFor="targetRevenue">Target Revenue from Ticket sales*</label>
              <input
                type="number"
                id="targetRevenue"
                name="targetRevenue"
                value={formData.targetRevenue}
                onChange={handleInputChange}
                required
              />
            </div>
            </div>

            <div className="event-form-row">
            <div className="event-form-group">
              <label htmlFor="eventLocation">Event Location *</label>
              <input
                type="text"
                id="eventLocation"
                name="eventLocation"
                value={formData.eventLocation}
                onChange={handleInputChange}
                required
              />
            </div>
            

            <div className="event-form-group">
              <label htmlFor="total_attendees">Total Attendees</label>
              <input
                type="number"
                id="total_attendees"
                name="total_attendees"
                value={formData.total_attendees}
                onChange={handleInputChange}
                min="0"
              />
            </div>

            <div className="event-form-group">
              <label htmlFor="averageTicketPrice">
                Average Ticket Price<span className="required">*</span>
              </label>
              <input
                type="number"
                id="averageTicketPrice"
                name="averageTicketPrice"
                value={formData.averageTicketPrice}
                onChange={handleInputChange}
                required
              />
            </div>


            </div>

            <div className="event-form-row">



             <div className="event-form-group">
              <label htmlFor="timezone">Set Timezone *</label>
              <select
                id="timezone"
                name="timezone"
                value={formData.timezone}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a timezone</option>
                {Object.entries(groupedTimezones).map(([continent, zones]) => (
                  <optgroup key={continent} label={continent}>
                    {zones.map((tz) => (
                      <option key={tz} value={tz}>
                        {tz} ({moment.tz(tz).format('Z')})
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>

            <div className="event-form-group">
              <label htmlFor="currency">Set Currency *</label>
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                required
              >
                <option value="USD">USD</option>
                <option value="AUD">AUD</option>
                <option value="NZD">NZD</option>
                <option value="GBP">GBP</option>
                <option value="Euro">Euro</option>
              </select>
            </div>

            <div className="event-form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
              />
            </div>


            <div className="event-form-group">
              <label>Did you hit the target?</label>
              <div className="event-toggle-switch">
                <div 
                  className={`toggle-button ${formData.hitTarget ? 'active' : ''}`}
                  onClick={() => handleCheckboxChange({ 
                    target: { 
                      name: 'hitTarget', 
                      checked: !formData.hitTarget 
                    }
                  })}
                >
                  <div className="toggle-labels">
                    <span className="label-yes">Yes</span>
                    <span className="label-no">No</span>
                  </div>
                  <div className="toggle-slider" />
                </div>
              </div>
            </div>
            
            </div>
          <div className="event-form-row">
            <div className="event-form-group full-width">
              <h3>Platforms</h3>
              <div className="platforms-container">
              <div className="platform-group">
                <h4>Offline</h4>
                <div className="platform-options">
                  {offlinePlatforms.map((platform) => renderPlatformButton(platform, 'offline'))}
                </div>
              </div>
              <div className="platform-group">
                <h4>Online</h4>
                <div className="platform-options">
                  {onlinePlatforms.map((platform) => renderPlatformButton(platform, 'online'))}
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button type="submit" className="btn-next">Next</button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default PostEventForm;