import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SingleEventComparison.css';

const SingleEventComparison = ({ eventName, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [preEventData, setPreEventData] = useState(null);
  const [postEventData, setPostEventData] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEventData();
  }, [eventName]);

  const fetchEventData = async () => {
    try {
      setLoading(true);
      setError(null);
  
      // Fetch all required data
      const [preEventResponse, postEventResponse, metricsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/pre_event_details/${encodeURIComponent(eventName)}`),
        axios.get(`${process.env.REACT_APP_API_URL}/post_event_details/${encodeURIComponent(eventName)}`),
        axios.get(`${process.env.REACT_APP_API_URL}/get_metric/${encodeURIComponent(eventName)}`)
      ]);
  
      // Process the data
      const preEventData = preEventResponse.data;
      const postEventData = postEventResponse.data[0]; // Note the [0] here
      const metricsData = metricsResponse.data[0] || {};
  
      console.log('Pre-event data:', preEventData);
      console.log('Post-event data:', postEventData);
      console.log('Metrics data:', metricsData);
  
      setPreEventData(preEventData);
      setPostEventData(postEventData);
      setMetrics(metricsData);
  
    } catch (error) {
      console.error('Error fetching event data:', error);
      setError('Failed to load event comparison data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="comparison-overlay">
        <div className="comparison-modal">
          <div className="loading">Loading comparison...</div>
        </div>
      </div>
    );
  }

  const formatPlatforms = (platforms) => {
    if (!platforms) return [];
    
    // If it's already an array, return it
    if (Array.isArray(platforms)) {
      return platforms;
    }
    
    // If it's a string, process it
    if (typeof platforms === 'string') {
      return platforms
        .replace(/[{}"]/g, '')
        .split(',')
        .map(p => p.trim())
        .filter(p => p);
    }
    
    return [];
  };

  return (
    <div className="comparison-overlay">
      <div className="comparison-modal">
        <div className="comparison-header">
          <h2>Pre vs Post Event Comparison - {eventName}</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="comparison-content">
          {/* Main Metrics Comparison */}
          <div className="metrics-comparison">
            <h3>Key Metrics Comparison</h3>
            <div className="comparison-grid">
              <div className="metric-block">
                <h4>Marketing Budget</h4>
                <div className="comparison-row">
                  <div className="pre">
                    <label>Pre-Event</label>
                    <span>${parseFloat(preEventData?.total_marketing_budget || 0).toLocaleString()}</span>
                  </div>
                  <div className="post">
                    <label>Post-Event</label>
                    <span>${parseFloat(postEventData?.total_marketing_budget || 0).toLocaleString()}</span>
                  </div>
                </div>
              </div>

              <div className="metric-block">
                <h4>Revenue Target vs Actual</h4>
                <div className="comparison-row">
                  <div className="pre">
                    <label>Target</label>
                    <span>${parseFloat(preEventData?.target_revenue || 0).toLocaleString()}</span>
                  </div>
                  <div className="post">
                    <label>Actual</label>
                    <span>${parseFloat(metrics?.overallrevenue || 0).toLocaleString()}</span>
                  </div>
                </div>
              </div>

              <div className="metric-block">
                <h4>Registration Target vs Actual</h4>
                <div className="comparison-row">
                  <div className="pre">
                    <label>Target</label>
                    <span>{parseInt(preEventData?.target_ticket_sales || 0).toLocaleString()}</span>
                  </div>
                  <div className="post">
                    <label>Actual</label>
                    <span>{parseInt(metrics?.totalregistrations || 0).toLocaleString()}</span>
                  </div>
                </div>
              </div>

              <div className="metric-block highlight">
                <h4>Performance Analysis</h4>
                <div className="comparison-row">
                  <div className="metric-item">
                    <label>ROI</label>
                    <span className="highlight-value">
                      {(((metrics?.overallrevenue || 0) - (postEventData?.total_marketing_budget || 0)) / (postEventData?.total_marketing_budget || 1) * 100).toFixed(2)}%
                    </span>
                  </div>
                  <div className="metric-item">
                    <label>Target Achievement</label>
                    <span className="highlight-value">
                      {((metrics?.totalregistrations || 0) / (preEventData?.target_ticket_sales || 1) * 100).toFixed(2)}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Marketing Channels Comparison */}
          <div className="channels-comparison">
  <h3>Marketing Channels</h3>
  <div className="channels-grid">
    <div className="channel-block">
      <h4>Pre-Event Planned Channels</h4>
      <div className="channel-lists">
        <div className="online-channels">
          <label>Online</label>
          <div className="channel-tags">
            {formatPlatforms(preEventData?.online_platforms).map((platform, i) => (
              <span key={i} className="channel-tag online">
                {platform.replace(/_/g, ' ')}
              </span>
            ))}
          </div>
        </div>
        <div className="offline-channels">
          <label>Offline</label>
          <div className="channel-tags">
            {formatPlatforms(preEventData?.offline_platforms).map((platform, i) => (
              <span key={i} className="channel-tag offline">
                {platform.replace(/_/g, ' ')}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>

    <div className="channel-block">
      <h4>Post-Event Actual Channels</h4>
      <div className="channel-lists">
        <div className="online-channels">
          <label>Online</label>
          <div className="channel-tags">
            {formatPlatforms(postEventData?.online_platforms).map((platform, i) => (
              <span key={i} className="channel-tag online">
                {platform.replace(/_/g, ' ')}
              </span>
            ))}
          </div>
        </div>
        <div className="offline-channels">
          <label>Offline</label>
          <div className="channel-tags">
            {formatPlatforms(postEventData?.offline_platforms).map((platform, i) => (
              <span key={i} className="channel-tag offline">
                {platform.replace(/_/g, ' ')}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
    </div>
  );
};

export default SingleEventComparison;