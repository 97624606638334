import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styles from './App.css';
import EventList from './components/MainPage/EventList';
import PostEventForm from './components/CreateEventPage/PostEventForm';
import PreEventForm from './components/CreateEventPage/PreEventForm';
import SideBar from './components/MainPage/SideBar';
import EventTable from './components/CreateEventPage/EventTable';
import PreEventTable from './components/CreateEventPage/PreEventTable';  // Add this import
import EventDetails from './components/CreateEventPage/EventDetails';
import PreEventDetails from './components/CreateEventPage/PreEventDetails';  // Add this import
import UniversalHeader from './components/UniversalHeader';
import Login from './components/LoginPage/Login';
import ProfileForm from './components/ProfilePage/ProfileForm';
import Dashboard from './components/MainPage/DashBoard';
import PrivacyPolicy from './components/TermsAndPolicy/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndPolicy/TermsAndConditions';

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const showHeader = location.pathname !== '/';

  useEffect(() => {
    localStorage.setItem('lastPath', location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const lastPath = localStorage.getItem('lastPath');
    if (lastPath && lastPath !== location.pathname) {
      navigate(lastPath);
    }
  }, []);

  return (
    <div className={styles.app}>
      {location.pathname !== '/terms-and-conditions' && location.pathname !== '/privacy-policy' && showHeader && <UniversalHeader />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/eventlist" element={<EventList />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="*" element={
          <div className={styles.contentWrapper}>
            <SideBar />
            <main className={styles.mainContent}>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-postevent" element={<PostEventForm />} />
                <Route path="/create-preevent" element={<PreEventForm />} />
                {/* Add pre-event specific routes */}
                <Route path="/preeventtable" element={<PreEventTable />} />
                <Route path="/pre-event-details" element={<PreEventDetails />} />
                {/* Existing post-event routes */}
                <Route path="/eventtable" element={<EventTable />} />
                <Route path="/event-details" element={<EventDetails />} />
                <Route path="/profile" element={<ProfileForm />} />
              </Routes>
            </main>
          </div>
        } />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;