import React, { useState } from 'react';
import './DeleteEventPopup.css';

const DeleteEventPopup = ({ event, onClose, onConfirm }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [selectedPhases, setSelectedPhases] = useState({
    pre: event.phases?.pre_event || false,
    post: event.phases?.post_event || false
  });

  const handlePhaseToggle = (phase) => {
    setSelectedPhases(prev => ({
      ...prev,
      [phase]: !prev[phase]
    }));
  };

  const handleDelete = async () => {
    // Check if at least one phase is selected
    if (!selectedPhases.pre && !selectedPhases.post) {
      alert('Please select at least one phase to delete');
      return;
    }

    setIsDeleting(true);
    
    try {
      // Convert selected phases to array of phases to delete
      const phasesToDelete = [];
      if (selectedPhases.pre) phasesToDelete.push('pre');
      if (selectedPhases.post) phasesToDelete.push('post');

      await onConfirm(event.event_name, phasesToDelete);
      setDeleteSuccess(true);
      
      // Close popup after 2 seconds
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Delete failed:', error);
      setIsDeleting(false);
      alert('Failed to delete event. Please try again.');
    }
  };

  if (deleteSuccess) {
    return (
      <div className="delete-popup-overlay">
        <div className="delete-popup-content success">
          <div className="success-message">
            <i className="fas fa-check-circle"></i>
            <h3>Event Deleted Successfully</h3>
            <p>The selected phases have been deleted.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="delete-popup-overlay">
      <div className="delete-popup-content">
        <h3>Delete Event</h3>
        <p>Are you sure you want to delete this event? This action cannot be undone.</p>
        
        <div className="phase-selection">
          <p>Select phases to delete:</p>
          <div className="checkbox-group">
            {event.phases?.pre_event && (
              <label>
                <input
                  type="checkbox"
                  checked={selectedPhases.pre}
                  onChange={() => handlePhaseToggle('pre')}
                  disabled={isDeleting}
                />
                Pre-Event Phase
              </label>
            )}
            {event.phases?.post_event && (
              <label>
                <input
                  type="checkbox"
                  checked={selectedPhases.post}
                  onChange={() => handlePhaseToggle('post')}
                  disabled={isDeleting}
                />
                Post-Event Phase
              </label>
            )}
          </div>
        </div>

        <div className="delete-popup-actions">
          <button 
            className="cancel-button" 
            onClick={onClose}
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button 
            className="delete-button" 
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <i className="fas fa-spinner fa-spin"></i>
                Deleting...
              </>
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteEventPopup;